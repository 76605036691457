import { useEffect, useState } from 'react';
// MUI Table stuff
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import Tooltip from '@mui/material/Tooltip';

import { useAppContext, BroadcastType } from 'app-context';
import { NodeType } from 'utils/const';

const header = ['Property', 'Value'];
const prop2Text = {
    'text': 'Text',
    'id': 'ID',
    'node_type': 'Type'
};

const NodeTypeTranslation = {
    [NodeType.Node]: 'Folder', //'Node',
    [NodeType.Item]: 'Item',
    [NodeType.SubItem]: 'Street Item', //'SubItem',
    [NodeType.NodeItem]: 'Street Entry', //'NodeItem',
    [NodeType.NodeSubItem]: 'Street Sub-item', //'NodeSubItem',
    [NodeType.Group]: 'Group',
}

const PropsTableRow = ({ name, value }) => {
    // decode node type
    const propValue = name === prop2Text['node_type'] ? NodeTypeTranslation[value] : value;

    return (
        <TableRow key={`${name}-row`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell key={`${name}-property`}>{name}</TableCell>
            <TableCell key={`${name}-value`}>
                <Tooltip title={propValue} placement="top-start" disableInteractive>
                    <TextField
                        variant='standard'
                        size='small'
                        fullWidth
                        value={propValue}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Tooltip title="Copy to clipboard" disableInteractive>
                                        <IconButton aria-label="copy" size="small" onClick={async (e) => {
                                            await navigator.clipboard.writeText(propValue);
                                            const animationClassName = 'flashBG';
                                            /**@type {Element}*/
                                            const copyButton = e.target.closest('button');
                                            /**@type {Element}*/
                                            // const parentTextField = parentElement.offsetParent;
                                            const parentTextField = copyButton.offsetParent;
                                            // animate the click
                                            copyButton.classList.add(animationClassName);
                                            parentTextField?.classList.add(animationClassName);
                                            // reset animation class
                                            setTimeout(() => {
                                                copyButton.classList.remove(animationClassName);
                                                parentTextField?.classList.remove(animationClassName);
                                            }, 1000)
                                        }}>
                                            <ContentCopyRoundedIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

export const ItemDetailTable = () => {
    const { dataBroadcaster: { data: broadcastData } } = useAppContext();
    const [treeNode, setTreeNode] = useState(null);

    useEffect(() => {
        console.log('broadcastData', broadcastData);
        if (broadcastData.action?.type === BroadcastType.SetCurrentStreetItem) {
            setTreeNode(broadcastData.treeNode);
        }
    }, [broadcastData]);

    return (
        <TableContainer component={Paper} sx={{ maxHeight: '100%', overflowX: 'initial' }}>
            <Table stickyHeader size='small' aria-label="table items view">
                {/* <Table stickyHeader size='small'> */}
                <TableHead>
                    <TableRow key={'header'}>
                        {header.map((headerName) => (<TableCell key={headerName}>{headerName}</TableCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {treeNode &&
                        Object.keys(prop2Text).map(propName => (<PropsTableRow key={propName} name={prop2Text[propName]} value={treeNode[propName]} />))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
};