// React
import React, { useEffect, useRef } from 'react';

// Code Highlighter
// import hljs from 'highlight.js/lib/core';
// import javascript from 'highlight.js/lib/languages/javascript';
// import xml from 'highlight.js/lib/languages/xml';
// import 'highlight.js/styles/atom-one-dark.css';

import Editor from "@monaco-editor/react";

import { Components } from 'utils/const';

// reactive (RxJS)
import { dataBusStore, useStore } from 'stores';

import { useAppContext } from "app-context";

const ME = Components.CODE_EDITOR.key;

export const vmtLogLanguage = 'vmtLogLanguage';
export const vmtLogTheme = 'vmtLogTheme';

const CodePrettifier = ({
    code,
    lineToAppend = null,
    vmtCommand = null,
    contentToScroll = '',
    language = 'javascript',
    // theme = 'vs-dark',
    options = {
        readOnly: true
    }
}) => {
    const { session: { theme } } = useAppContext();
    const editorRef = useRef(null);
    const monacoRef = useRef(null);

    const [, setDataBusContent] = useStore(dataBusStore);

    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
        monacoRef.current = monaco;

        // search for the nearest block id above 
        editor.onDidChangeCursorPosition((e) => {
            // console.log('onDidChangeCursorPosition', e);
            const selectedLineNumber = e.position.lineNumber;
            const model = editor.getModel();
            // console.log(lineContent);
            let matchResult;
            const codeBlockIdRE = /id:\s+'(.*?)'/;
            const logBlockIdRE = /Block:\s(.*)$/;

            for (let lineNumber = selectedLineNumber; lineNumber !== 1; --lineNumber) {
                const lineContent = model.getLineContent(lineNumber);
                if ((matchResult = lineContent.match(codeBlockIdRE))) {
                    console.log('Code Block Id', matchResult[1]);
                    setDataBusContent({
                        to: Components.BASEMENT.key,
                        from: ME,
                        content: {
                            blockId: matchResult[1]
                        }
                    });

                    break;
                }
                else if ((matchResult = lineContent.match(logBlockIdRE))) {
                    console.log('Log Block Id', matchResult[1]);
                    setDataBusContent({
                        to: Components.BASEMENT.key,
                        from: ME,
                        content: {
                            blockId: matchResult[1]
                        }
                    });
                    break;
                }
            }
        })
        // Register a new language
        monaco.languages.register({ id: vmtLogLanguage });

        // Register a tokens provider for the language
        monaco.languages.setMonarchTokensProvider(vmtLogLanguage, {
            tokenizer: {
                root: [
                    [/\[error.*/, 'custom-error'],
                    [/Block:/, 'custom-notice'],
                    [/\[[a-zA-Z 0-9:.-]+\]/, 'custom-date'],
                    // [/>>.*?<</, 'spotted-content'],
                    [/>>/, 'spotted-content', '@spotted_content'],
                ],
                spotted_content: [
                    [/<</, 'spotted-content', '@pop'],
                    [/./, 'spotted-content.body'],
                ],

            }
        });

        // Define a new theme that contains only rules that match this language
        monaco.editor.defineTheme(vmtLogTheme, {
            base: 'vs-dark',
            inherit: true,
            rules: [
                { token: 'spotted-content', foreground: 'F0F0F0', fontStyle: 'bold' },
                { token: 'spotted-content.body', foreground: '9FD67A'/*, fontStyle: 'italic'*/ },
                { token: 'custom-error', foreground: 'ff0000', fontStyle: 'bold' },
                { token: 'custom-notice', foreground: 'FFA500', fontStyle: 'underline' },
                { token: 'custom-date', foreground: '008800' }
            ],
            colors: {
                'editor.foreground': '808080'
            }
        });
    }

    useEffect(() => {
        console.log('THEME', theme.themeName);
        // set up a theme
        document.querySelector('body').setAttribute('data-theme', theme.themeName);
    }, [theme.themeName]);

    useEffect(() => {
        const editor = editorRef.current;
        const monaco = monacoRef.current;
        // console.log('>>>>> vmtCommand', vmtCommand);
        if (vmtCommand !== null && editor !== null) {
            switch (vmtCommand?.command) {
                case 'VMT_CLEAR_EXECUTION_LOG':
                    editor.setValue('');
                    break;
                case 'VMT_REVEAL_LAST_LINE':
                    const lastLineNumber = editor.getModel().getLineCount();
                    // editor.revealLineInCenter(lastLineNumber);
                    editor.revealLine(lastLineNumber);
                    editor.setSelection(new monaco.Range(lastLineNumber, 1, lastLineNumber, 1));
                    break;
                default:
                    break;
            };
        }
    }, [vmtCommand]);

    useEffect(() => {
        const editor = editorRef.current;
        const monaco = monacoRef.current;
        if (lineToAppend !== null && editor !== null) {
            editor.updateOptions({ readOnly: false });
            let lastLineNumber = editor.getModel().getLineCount();
            editor.executeEdits("",
                [
                    {
                        range: new monaco.Range(lastLineNumber, 1, lastLineNumber, 1),
                        text: lineToAppend,
                        //forceMoveMarkers: true
                    }
                ]
            );
            editor.updateOptions({ readOnly: true });
            lastLineNumber = editor.getModel().getLineCount();
            // editor.revealLineInCenter(lastLineNumber);
            editor.revealLine(lastLineNumber);
            editor.setSelection(new monaco.Range(lastLineNumber, 1, lastLineNumber, 1));
        }
    }, [lineToAppend]);

    useEffect(() => {
        const editor = editorRef.current;
        const monaco = monacoRef.current;
        if (contentToScroll !== '' && editor !== null) {
            const model = editor.getModel();
            const matches = model.findMatches(contentToScroll);
            if (matches.length === 0) return;
            const range = matches[0].range;
            const selectionLineNumber = range.startLineNumber;
            editor.revealLineInCenterIfOutsideViewport(selectionLineNumber, 0 /* ScrollType.Smooth */);
            // editor.revealLineInCenter(range.startLineNumber);
            const lineToSelectRange = new monaco.Range(selectionLineNumber, 1, selectionLineNumber + 1, 1);
            editor.setSelection(lineToSelectRange);
            // editor.setSelection(range);
        }
    }, [contentToScroll]);
    // const codeBlockRef = React.useRef(null);

    // React.useEffect(() => {
    //     hljs.registerLanguage('javascript', javascript);
    //     hljs.registerLanguage('xml', xml);
    //     // eslint-disable-next-line
    // }, []);

    // React.useLayoutEffect(() => {
    //     if (code === null) return;
    //     hljs.highlightElement(codeBlockRef.current);
    // }, [code]);

    // return (
    //     <pre
    //         ref={codeBlockRef}
    //         style={{ color: 'aliceblue', fontSize: '14px', userSelect: 'text', cursor: 'text', overflow: 'visible'}}>
    //         {code}
    //     </pre>
    // );

    return (
        <Editor
            // height="90vh"
            onMount={handleEditorDidMount}
            defaultLanguage={language}
            // theme={theme}
            theme={theme.themeName === 'dark' ? 'vs-dark' : 'vs-light'}
            options={options}
            defaultValue=''
            value={code}
        />
    );
};

export default CodePrettifier;