import {
    action,
    computed,
    makeObservable,
    observable,
    // ObservableMap,
} from 'mobx';

import Construction from 'models/construction';
import IConstruction from 'types/construction';
import AppStore from 'stores/app';

export default class ConstructionStore {

    @observable constructionById = new Map<string, IConstruction>();
    @observable lastUpdatedConstructionId: string | null = null;


    constructor(private store: AppStore) {
        makeObservable(this);
    }

    @action load(constructions: IConstruction[]) {
        this.constructionById.clear();
        constructions.forEach((construction) => this.constructionById.set(construction.id, new Construction(this.store, construction)));
    }

    @action add(construction: IConstruction) {
        this.constructionById.set(construction.id, construction);
        this.lastUpdatedConstructionId = construction.id;
    }

    @action update(construction: IConstruction) {
        this.add(construction);
    }

    @action delete(id: string): IConstruction | undefined {
        const construction = this.byId(id);
        this.constructionById.delete(id);
        return construction;
    }

    @computed get size(): Number {
        return this.constructionById.size;
    }

    @computed
    get nodes(): IConstruction[] {
        return Array.from(this.constructionById.values())
    }

    byId(id: string): IConstruction | undefined {
        return this.constructionById.get(id);
    }

    /**
     * @param {string[]} brickIds - Array of bricks' Ids
     * @returns - Map: 'brick_id'-> array of IConstruction
     */
    byBricksUsages(brickIds: string[]): Map<string, IConstruction[]> {
        const mapUsages = new Map<string, IConstruction[]>();
        const constructions = this.nodes;
        const constrTreeNodes = this.store.constructionTree.nodes;
        brickIds.forEach(brickId => {
            const depConstrs = constructions
                .filter(constr => constr.bricks.includes(brickId))
                .map(constr => {
                    const constrTreeItem = constrTreeNodes.find(treeNode => treeNode.data?.reference_id === constr.id);
                    // replace the content of the 'workspace_name' with tree item text
                    constrTreeItem && (constr.workspace_name = constrTreeItem.text);
                    return constr;
                });
            console.log(brickId, depConstrs);
            mapUsages.set(brickId, [...depConstrs]);
        });
        return mapUsages;
    };
}