export const Colors = Object.freeze({
    // Construction block color
    BASE_TEST_COLOR: '#2E476A',

    // WebdriveIO block color
    WDIO_CONFIGURATION_COLOR: '#3E576A',
    
    // Brick and bricks' controls blocks colors
    BRICK_COLOR: '#444C5C', //'#444C5C',
    CONTROL_COLOR: '#4E576A', //'#4E576A',
    GROUP_COLOR: '#2E476A', //'#4E576A',
    
    // Action blocks colors
    ACTION_INPUT_COLOR: '#CE5A57',
    ACTION_GETTER_COLOR: '#E1B16A',
    ACTION_VERIFY_COLOR: '#78A5A3',
    
    // Browser actions
    ACTION_BROWSER_COLOR: '#138D90',
});