import { useState, useEffect, useCallback, useRef, memo } from 'react';
import { /*autorun,*/ reaction } from 'mobx';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { lightBlue } from '@mui/material/colors';
import DialogContentText from '@mui/material/DialogContentText';

import { css } from 'aphrodite';
import * as Blockly from 'blockly/core';
import 'blockly/blocks';
import 'blockly_vmt/vavanya-generator';

// Brick Factory
import { useBFBlocklyController } from './blockly_stuff/useBFBlocklyController';
import useBrickFactory from './useBrickFactory';

// VMT
import BlocklyComponent, { Block } from 'components/BlocklyComponent';
import GridPropetryEditor from './GridPropertyEditor';

import styles from 'components/Styles';
import { FloatingButton } from 'components/FloatingControls';
import DialogModal from 'components/Modals/DialogModal';
import TableDialogModal from 'components/Modals/TableDialogModal';
// eslint-disable-next-line no-unused-vars
import IConstruction from 'types/construction';
import useConstruction from 'utils/hooks/useConstruction';
import { useAppContext } from "app-context";

// reactive (RxJS)
import { brickId2EditStore, useStore } from 'stores';

// Brick Factory State Machine
import { assign } from "xstate";
import { useActorRef, /*useMachine,*/ useSelector } from '@xstate/react';
import { machine } from './brickFactoryMachine';

/**
 * Name of block if not named.
 */
const emptyInitialXml = '<xml xmlns="https://developers.google.com/blockly/xml"></xml>';


// // Add preffix to standard console.log function
// const origConsoleLog = console.log;
// console.log = function () {
//     // BBF <--- preffix 
//     arguments[0] = util.format('BBF', arguments[0]);
//     origConsoleLog.apply(origConsoleLog, arguments);
// };

export const BrickFactoryComponent = memo(() => {
    // console.log('<BrickFactoryComponent/>');

    const { /*api,*/ store } = useAppContext();
    const { updateConstruction } = useConstruction();
    const [brickFactoryWorkspace, setBrickFactoryWorkspace] = useState(null);
    const workspaceRef = useRef(null);

    const {
        blocklyChangeListener,
        blocklyEvent,
        selectedBlockData,
        getHandleResize,
        labelPos
    } = useBFBlocklyController(); // !!! MOVED to BF hook

    const constrToUpdateRef = useRef(null);

    const {
        loadBrickById,
        saveBrick,
        originalBrick,
        origWsXml,
    } = useBrickFactory();

    const [brickId2Edit] = useStore(brickId2EditStore);

    const [amendState, setAmendState] = useState(false);

    // const [/*snapshot*/, /*send*/, actorRef] = useMachine(machine.provide(
    const actorRef = useActorRef(machine.provide(
        {
            // actors:{
            //     inv_saveBrick: fromPromise(async ({ context, event }) => {
            //         console.log('>>>> (INVOKE) saveBrick', context, event);
            //         const { current: workspace } = workspaceRef;
            //         await saveBrick(workspace);
            //     }),
            //     inv_updateUsages: fromPromise(async ({ context, event }) => {
            //         const { current: usages } = constrToUpdateRef;
            //         await usages.forEach(async (construction) => {
            //             console.log('>>>> (INVOKE) Updating construction:', construction.workspace_name);
            //             await updateConstruction(construction.id, context.brickId);
            //         });
            //     }),
            // },
            actions: {
                loadBrick: ({ context, event }) => {
                    console.log('>>>> loadBrick', context, event);
                    context.brickId = context.newBrickId;
                    const { current: workspace } = workspaceRef;
                    prepareBrickUsageInfo(context.brickId);
                    loadBrickById(workspace, context.brickId);
                    // Move to the empty_state if there is no brick selected
                    if (context.brickId === null) {
                        const { value: currentState } = actorRef.getSnapshot();
                        if (currentState === 'view_brick') {
                            actorRef.send({ type: 'RESET_VIEW' });
                        }
                    }
                },
                saveBrick: ({ context, event }) => {
                    console.log('>>>> saveBrick', context, event);
                    const { current: workspace } = workspaceRef;
                    saveBrick(workspace);
                },
                // updateUsages: async ({ context, event }) => {
                //     const { current: usages } = constrToUpdateRef;
                //     await usages.forEach(async (construction) => {
                //         console.log('>>>> Updating construction:', construction.workspace_name);
                //         await updateConstruction(construction.id, context.brickId);
                //     });
                // },
                updateUsages: ({ context, event }) => {
                    console.log('>>>> Updating construction <<<<');
                    const { current: usages } = constrToUpdateRef;
                    const { current: workspace } = workspaceRef;
                    saveBrick(workspace)
                        .then(() => {
                            usages.forEach(async (construction) => {
                                console.log('>>>> Updating construction:', construction.workspace_name);
                                updateConstruction(construction.id, context.brickId);
                            });
                        })
                },
                // store the brick Id to load in the context
                setBrickId: assign(({ event }) => {
                    console.log('>>>> setBrickId', event.brickId);
                    return { newBrickId: event.brickId }
                }),

            },
            guards: {
                "brickId != null": ({ context, event }) => {
                    console.log('>>>> GUARD (brickId != null)', context, event);
                    return event.brickId !== null;
                },
                iskBrickInUse: ({ context, event }) => {
                    console.log('>>>> GUARD (iskBrickInUse)', context, event);
                    const usages = store.construction.byBricksUsages([context.brickId]);
                    return usages.get(context.brickId).length !== 0;
                },
                "brickId != newBrickId": ({ context, event }) => {
                    console.log('>>>> GUARD (brickId != newBrickId)', context, event);
                    return context.brickId !== event.brickId;
                },
            },
        }
    ));

    const showSaveBrickModal = useSelector(actorRef, ({ context }) => context.showSaveBrickModal)
    const showUpdateBrickUsageModal = useSelector(actorRef, ({ context }) => context.showUpdateBrickUsageModal)

    // Subscribe for the machine's state changes
    useEffect(() => {
        console.log('SUBSCRIBE to State Machine changes');
        const subscription = actorRef.subscribe((snapshot) => {
            // simple logging
            console.log('>>>> STATE:', snapshot.value, snapshot.context);
        });
        // Start the machine
        actorRef.start();
        return subscription.unsubscribe;
    }, [actorRef]); // note: actor ref should never change

    // Store the workspace reference
    useEffect(() => {
        workspaceRef.current = brickFactoryWorkspace;
    }, [brickFactoryWorkspace]);

    // Renaming the brick in the tree while editing
    useEffect(() => {
        if (!brickId2Edit) return;

        const disposer = reaction(
            () => store.brick.bricksById.get(brickId2Edit),
            () => {
                console.log('>>> reload brick <<<');
                loadBrickById(brickFactoryWorkspace, brickId2Edit);
            });

        return () => {
            disposer();
        };
    }, [store, brickId2Edit, loadBrickById, brickFactoryWorkspace]);

    // Load brick
    useEffect(() => {
        // console.log('LOAD_BRICK');
        actorRef.send({ type: 'LOAD_BRICK', brickId: brickId2Edit });
    }, [actorRef, brickId2Edit]);

    // Amend brick
    useEffect(() => {
        const { value: currentState } = actorRef.getSnapshot();

        console.log('>>>> Brick Mode', amendState, currentState);
        if (currentState === 'view_brick' && amendState) {
            actorRef.send({ type: 'AMEND_BRICK' });
        }
        else if (currentState === 'amended_brick') {
            actorRef.send({ type: 'UNDO_CHANGES' });
        }
    }, [actorRef, amendState]);

    // Prepare the data for update brick usages
    const prepareBrickUsageInfo = useCallback((brickId) => {
        console.log('>>>> showUpdateBrickUsageModal');
        const usages = store.construction.byBricksUsages([brickId]);
        console.log('>>>> Brick Usages', usages);
        // Show update constr dialog
        if (usages.get(brickId).length !== 0) {
            // Create an array of the constrcutions to update
            const constrcutions = usages.get(brickId);
            constrToUpdateRef.current =
                [...constrcutions
                    .map(constr => {
                        return {
                            ...constr,
                            text: constr.workspace_name,
                            cellStyle: { color: lightBlue[500] }
                        }
                    })
                ];
        }
    }, [store.construction])

    // set Blockly change listerner and 'resize' event listener
    useEffect(() => {
        const handleResize = getHandleResize(brickFactoryWorkspace);
        window.addEventListener('resize', handleResize)
        brickFactoryWorkspace?.addChangeListener(blocklyChangeListener);
        // Disable blocks not attached to the 'factory_base' block.
        brickFactoryWorkspace?.addChangeListener(Blockly.Events.disableOrphans);

        return () => {
            window.removeEventListener('resize', handleResize);
            brickFactoryWorkspace?.removeChangeListener(blocklyChangeListener);
            brickFactoryWorkspace?.removeChangeListener(Blockly.Events.disableOrphans);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getHandleResize, blocklyChangeListener, brickFactoryWorkspace]);

    // monitor for changes in the currently loaded brick
    useEffect(() => {
        if (!brickFactoryWorkspace) return;
        if (blocklyEvent?.type === Blockly.Events.VIEWPORT_CHANGE) return;

        const currentWsXml = Blockly.Xml.workspaceToDom(brickFactoryWorkspace);
        const currentWsXmlText = Blockly.Xml.domToText(currentWsXml);
        // console.log('>>>> currentWsXmlText', currentWsXmlText);
        if (currentWsXmlText === emptyInitialXml) return;
        // const currentBrickMode = brickModeRef.current;
        // console.log('curr', currentWsXmlText);
        // console.log('orig', origWsXml);
        if (currentWsXmlText !== origWsXml) {
            // console.log('currentWsXmlText !== origWsXml');
            setAmendState(true);
            // setBrickMode({ ...currentBrickMode, touched: true });
        }
        else {
            // console.log('currentWsXmlText === origWsXml');
            setAmendState(false);
            // setBrickMode({ ...currentBrickMode, touched: false });
        }
    }, [blocklyEvent, brickFactoryWorkspace, origWsXml]);

    const onPropertyChange = useCallback((newBlockData) => {
        const block = brickFactoryWorkspace?.getBlockById(newBlockData?.blockId);
        console.log('onPropertyChange', newBlockData, block);
        if (block) {
            const blockData = newBlockData;
            delete blockData.blockId;
            block['data'] = JSON.stringify(blockData);
            const fieldName = blockData.type === 'brick' ? 'NAME' : 'INPUT_CONTROL_NAME';
            // update name of the brick or control block
            block.setFieldValue(blockData.name, fieldName);
            // trigger a Blockly Event to initiate changes checking logic
            brickFactoryWorkspace.fireChangeListener(new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))());
        }
    }, [brickFactoryWorkspace]);

    return (
        <>
            {/* Brick composing area */}
            <Grid
                container
                direction="column"
                // justifyContent="space-between"
                alignItems="stretch"
            >
                <Grid item >
                    <Box className={css(styles.brickFactoryContent)}>
                        <BlocklyComponent
                            // ref={setBfRef}
                            workspaceSetter={setBrickFactoryWorkspace}
                            readOnly={false}
                            trashcan={true}
                            media={'media/'}
                            horizontalLayout={true}
                            // toolboxPosition={'end'}
                            grid={{
                                spacing: 25,
                                length: 3,
                                colour: '#ccc',
                                snap: true
                            }}
                            move={{
                                scrollbars: true,
                                drag: true,
                                wheel: true
                            }}
                            zoom={{
                                startScale: 0.8,
                                controls: true,
                                wheel: true
                            }}
                            renderer={'zelos'}
                            initialXml={emptyInitialXml}>
                            <Block type="control_generic" />
                        </BlocklyComponent>
                    </Box>
                </Grid>
                <Grid item >
                    <div style={{ display: 'flex', height: '20vh' }}>
                        <GridPropetryEditor
                            blockData={selectedBlockData}
                            onChange={onPropertyChange}
                        />
                    </div>
                </Grid>
            </Grid>
            {/*{(brickId2Edit !== null && brickMode.touched === true) &&*/}
            {(amendState) &&
                (<FloatingButton
                    x={labelPos.x}
                    y={labelPos.y}
                    text='Save'
                    // onClick={() => { saveBrick(brickFactoryWorkspace) }}
                    onClick={() => { actorRef.send({ type: 'SAVE_BRICK' }) }}
                />)
            }
            <DialogModal
                title={`Unsaved brick (${originalBrick?.name})`}
                show={showSaveBrickModal}
                handleClose={() => { actorRef.send({ type: 'CANCEL' }) }}
                primaryBtnLabel='Save'
                onPrimary={() => { actorRef.send({ type: 'YES' }) }}
                secondaryBtnLabel='No'
                onSecondary={() => { actorRef.send({ type: 'NO' }) }}
                secondaryBtnLabel2='Cancel'
                onSecondary2={() => { actorRef.send({ type: 'CANCEL' }) }}
            >
                <DialogContentText>
                    Would you like to save the brick?
                </DialogContentText>
            </DialogModal>
            <TableDialogModal
                title='Saving Brick...'
                show={showUpdateBrickUsageModal}
                beforeContentDescription='Brick usage in the following constructions will be updated'
                tableHeaderItems={['Construction Name']}
                tableContentItems={constrToUpdateRef.current === null ? [] : constrToUpdateRef.current}
                fieldNames={['id', 'text']}
                handleClose={() => { actorRef.send({ type: 'CANCEL' }) }}
                primaryBtnLabel='Update'
                onPrimary={() => { actorRef.send({ type: 'UPDATE' }) }}
            />
        </>
    );
});
