import AppStore from "stores/app";
import AppApi from "apis/app";

export default class CliApi {
    private readonly url = '/cli';

    constructor(private api: AppApi, private store: AppStore) { }

    async getApiKey() {
        const res = await this.api.client.get(`${this.url}/api-key`);
        const { data: apiKey } = res;
        console.log('API KEY', apiKey);
        return apiKey;
    }
}