import React, { useContext, useState, useReducer, createContext, Dispatch, ReactNode, useRef } from "react";

import AppApi from "apis/app";
import AppStore from "stores/app";

import { LayoutMode, Themes } from "utils/const";
import { WorkspaceSvg } from "blockly";
import IBrick from "types/brick";
import IConstruction from "types/construction";
import ITreeNode from "types/treeNode";

export const store = new AppStore();
export const api = new AppApi(store);

interface ThemeType {
  themeName: Themes;
  setThemeName: Dispatch<Themes>;
}

interface LayoutType {
  layoutName: string;
  setLayoutName: Dispatch<LayoutMode>;
}

interface UserObject {
  userName: string;
  sessionDuration: number;
}

interface UserInfoType {
  userInfo: UserObject;
  setUserInfo: Dispatch<UserObject>;
}

interface DragAndDropContextType {
  sourceTreeIdRef: React.MutableRefObject<string>;
  currentTreeIdRef: React.MutableRefObject<string>;
  dragItemsRef: React.MutableRefObject<string[]>;
}

interface AuthInfoType {
  isAuthenticated: boolean;
  setIsAuthenticated: Dispatch<boolean>;
}

interface StartupInfoType {
  loading: boolean;
  setLoading: Dispatch<boolean>;
}

interface VmtBlocklyInfoType {
  basementWorkspace: WorkspaceSvg | null;
  setBasementWorkspace: Dispatch<WorkspaceSvg | null>;
  brickFactoryWorkspace: WorkspaceSvg | null;
  setBrickFactoryWorkspace: Dispatch<WorkspaceSvg | null>;
}

interface SessionContextType {
  theme: ThemeType;
  layout: LayoutType;
  user: UserInfoType;
  auth: AuthInfoType;
  startup: StartupInfoType;
  dragAndDropContext: DragAndDropContextType;
  vmtBlocklyContext: VmtBlocklyInfoType;
}

export enum BroadcastType {
  SetCurrentStreetItem,
  AppendExecutionLog,
};
// Define action types for data exchange
type BroadcastAction =
  | { type: BroadcastType.AppendExecutionLog; executionLogState: ExecutionLogState }
  | { type: BroadcastType.SetCurrentStreetItem; treeNode: ITreeNode };

interface ExecutionLogState {
  status: 'Idle' | 'Error' | 'Running' | 'Connected';
  logToAppend: string;
  log: string[];
}

// Define the shape of the data exchange
interface BroadcastData {
  brick: IBrick | null;
  construction: IConstruction | null;
  action: BroadcastAction | null;
  treeNode: ITreeNode | null;
  executionLogState: ExecutionLogState;
  // logToAppend: string;
}

// Define the initial state
const initialState: BroadcastData = {
  brick: null,
  construction: null,
  action: null,
  treeNode: null,
  executionLogState: { status: "Idle", logToAppend: '', log: [] },
};

// Define data exchange type for context
interface DataExchangeType {
  data: BroadcastData;
  dispatch: React.Dispatch<BroadcastAction>;
}

const CreateSessionContextType = (): SessionContextType => {
  const [themeName, setThemeName] = useState(Themes.Dark);
  const [layoutName, setLayoutName] = useState(LayoutMode.Construction);
  const [userInfo, setUserInfo] = useState({ userName: '', sessionDuration: 0 });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const sourceTreeId = useRef("");
  const currentTreeId = useRef("");
  const dragItems = useRef([]);
  // const dragAndDropContext = { sourceTreeId: "", currentTreeId: "", dragItems: [] };
  const [basementWorkspace, setBasementWorkspace] = useState(null);
  const [brickFactoryWorkspace, setBrickFactoryWorkspace] = useState(null);

  return {
    theme: {
      themeName,
      setThemeName
    },
    layout: {
      layoutName,
      setLayoutName
    },
    user: {
      userInfo,
      setUserInfo
    },
    auth: {
      isAuthenticated,
      setIsAuthenticated,
    },
    startup: {
      loading,
      setLoading,
    },
    vmtBlocklyContext: {
      basementWorkspace,
      setBasementWorkspace,
      brickFactoryWorkspace,
      setBrickFactoryWorkspace
    },
    dragAndDropContext: {
      sourceTreeIdRef: sourceTreeId,
      currentTreeIdRef: currentTreeId,
      dragItemsRef: dragItems
    },
  } as SessionContextType;
};


interface AppContextType {
  store: AppStore;
  api: AppApi;
  session: SessionContextType;
  dataBroadcaster: DataExchangeType;
}

const AppContext = createContext<null | AppContextType>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context as AppContextType;
};


// Reducer function
const reducer = (state: BroadcastData, action: BroadcastAction): BroadcastData => {
  switch (action.type) {
    case BroadcastType.SetCurrentStreetItem:
      return { ...state, treeNode: action.treeNode, action };
    case BroadcastType.AppendExecutionLog:
      const newExecutionLogState = action.executionLogState;
      const currentLog = newExecutionLogState.log || state.executionLogState.log;
      const updatedExecutionLogState = { ...newExecutionLogState, log: [...currentLog, newExecutionLogState.logToAppend] };
      return { ...state, executionLogState: updatedExecutionLogState, action };
    default:
      return state;
  }
};

interface AppProviderProps {
  children: ReactNode;
}

// Context provider component
export const AppProvider = ({ children }: AppProviderProps) => {
  const [data, dispatch] = useReducer(reducer, initialState);
  const session = CreateSessionContextType();

  return (
    <AppContext.Provider value={{ store, api, session, dataBroadcaster: { data, dispatch } }}>
      {children}
    </AppContext.Provider>
  );
};