import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from '@mui/material/CssBaseline';

import qs from 'qs';

import { useAppContext } from "./app-context";

import VApp from 'components/VApp';
import IdleMonitor from 'components/IdleMonitor';

import { auth, user as userApi } from 'utils/VApi';
import { apiEndpointStore, useStore } from 'stores';
import { themeLight, themeDark } from 'components/MuiThemes';
import LocalRunner from 'components/LocalRunner';

import { URIs } from 'utils/const';

const AuthWrapper = () => {
    const [isDeletingAccount, setIsDeletingAccount] = useState(false);
    const [loading, setLoading] = useState(true);
    const [, setApiEndpoint] = useStore(apiEndpointStore);
    // session's hooks
    // const session = CreateSessionContextType();

    const redirect = (url) => window.location.href = url;

    const { session: { theme: { themeName }, user: { setUserInfo }, auth: { isAuthenticated, setIsAuthenticated } }/*, loading, setLoading */ } = useAppContext(); //session;
    useEffect(() => {
        if (isAuthenticated && isDeletingAccount) {
            userApi()
                .anonymize()
                .then(({ data }) => {
                    console.log('ANONYMIZED', data);
                    if (data === 'deleted') {
                        console.log('REDIRECT TO IDP - SUCCESS');
                        // redirect to https://myaccount.vavanya.com/?accDeleteStatus=success
                        redirect(`${URIs.IDP}/?accDeleteStatus=success`);
                    }
                    else {
                        console.log('REDIRECT TO IDP - FAIL');
                        // redirect to https://myaccount.vavanya.com/?accDeleteStatus=fail
                        redirect(`${URIs.IDP}/?accDeleteStatus=fail`);
                    }
                })
        }
    }, [isAuthenticated, isDeletingAccount]);

    useEffect(() => {
        let queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        // use API endpoint provided via URL query
        if (queryString.api_endpoint !== undefined) {
            setApiEndpoint(queryString.api_endpoint);
        }
        else {
            window.localStorage.removeItem('API_ENDPOINT');
        }
        // deleteAccount
        queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        if (queryString.deleteAccount !== undefined) {
            setIsDeletingAccount(true);
        }

        auth()
            .tokenRefresh()
            .then(
                (res) => {
                    console.log(res);
                    if (res.status < 399) {
                        setUserInfo(res.data);
                        setIsAuthenticated(true);
                    }
                    setLoading(false);
                });
    }, [setApiEndpoint, setUserInfo, setIsAuthenticated, setLoading]);

    const loginWithRedirect = () => {
        auth()
            .login()
            .then(({ data }) => data.redirect && redirect(data.url));
    };

    if (loading) {
        return (
            <LinearProgress />
        );
    }

    return (
        <>
            {!isAuthenticated && (
                loginWithRedirect({})
            )}

            {(!isDeletingAccount && isAuthenticated) && (
                /* Initialize the application context */
                <>
                    {/* Global MUI theme provider*/}
                    <ThemeProvider theme={themeName === 'dark' ? themeDark : themeLight}>
                        {/* <CssBaseline /> */}
                        <VApp />
                        <IdleMonitor />
                    </ThemeProvider>
                    <LocalRunner />
                </>
            )}
        </>
    );
}

export default AuthWrapper;