// React
import { useState } from 'react';

// MUI
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// MUI icons
import ApiIcon from '@mui/icons-material/Api';
import FingerprintSharpIcon from '@mui/icons-material/FingerprintSharp';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

/*
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ConstructionIcon } from 'construction.svg';
// Usage example:
<SvgIcon component={ConstructionIcon} style={{ color: 'red' }} /> 
*/

// VMT
import InputModal from 'components/Modals/InputModal';
import AboutModal from 'components/Modals/AboutModal';

import { URIs, LayoutMode } from 'utils/const';
import { auth } from 'utils/VApi';
import { apiEndpointStore, useStore } from 'stores';
import { useAppContext } from "app-context";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        '&.Mui-selected': {
            color: theme.palette.mode === 'light' ? 'white' : theme.palette.primary.dark
        },
    },
}));

export const HeaderComponent = () => {
    const [showInputModal, setShowInputModal] = useState(false);
    const [showAboutModal, setShowAboutModal] = useState(false);
    const [showApiKeySnackbar, setShowApiKeySnackbar] = useState(false);
    const [visualMode, setVisualMode] = useState(LayoutMode.Construction);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [apiEndpoint, setApiEndpoint] = useStore(apiEndpointStore);
    const { session: { theme, layout, user: { userInfo }, auth: { setIsAuthenticated }, startup: { setLoading } }, api } = useAppContext();

    const handleVisualModeChange = (event, newVisualMode) => {
        if (newVisualMode === null) return;
        layout.setLayoutName(newVisualMode);
        setVisualMode(newVisualMode);
    };

    const toggleColorMode = () => {
        theme.setThemeName((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    }

    const handleMenuAnchorElementChange = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const changeApiEndpoint = () => {
        setMenuAnchorEl(null);
        setShowInputModal(true);
    };

    const logout = () => {
        setMenuAnchorEl(null);
        auth()
            .logout()
            .then(
                (res) => {
                    console.log(res);
                    setIsAuthenticated(false);
                    setLoading(false);
                }
            );
    };



    return (
        <>
            <AppBar color="primary" position="static" sx={{ height: 'min-content' }} >
                <Toolbar variant="dense">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setShowAboutModal(true)}
                    >
                        {/* <MenuIcon /> */}
                        {/* <LogoSVG /> */}
                        {/* <Icon /> */}
                        {/* <Icon classes={{ root: classes.iconRoot }}> */}
                        <img src="/favicon-32x32.png" alt='vavanya logo' />
                        {/* <img src="/favicon-16x16.png" /> */}
                        {/* </Icon> */}
                    </IconButton>
                    <Typography variant="body1" component="div" sx={{ fontSize: '0.75em', flexGrow: 1 }} >
                        #VMT = () =&gt; &#123; Vavanya-Means-Test &#125;
                    </Typography>

                    <StyledToggleButtonGroup
                        color="primary"
                        value={visualMode}
                        exclusive
                        onChange={handleVisualModeChange}
                        sx={{ flexGrow: 2 }}
                        aria-label="Visual Mode"
                    >

                        <ToggleButton value={LayoutMode.Construction}>
                            <Tooltip title={LayoutMode.Construction} placement="bottom-end">
                                {/* {VISUAL_MODES.Construction.display} */}
                                <BusinessOutlinedIcon />
                            </Tooltip>
                        </ToggleButton>

                        <ToggleButton value={LayoutMode.Streets} placement="bottom-end">
                            <Tooltip title={LayoutMode.Streets}>
                                {/* {VISUAL_MODES.Streets.display} */}
                                <EditRoadOutlinedIcon />
                            </Tooltip>
                        </ToggleButton>
                    </StyledToggleButtonGroup>

                    <IconButton sx={{ ml: 1 }} color="inherit" onClick={toggleColorMode}>
                        {theme.themeName === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        {/* <Brightness7Icon /> */}
                    </IconButton>
                    {/* User menu */}
                    <>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenuAnchorElementChange}
                            color="inherit"
                        >
                            <Typography variant="h6" component="div" >
                                {userInfo.userName}
                                &nbsp;
                            </Typography>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={menuAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem component="a" href={URIs.IDP}>
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText>Account Management</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={changeApiEndpoint}>
                                <ListItemIcon>
                                    <ApiIcon />
                                </ListItemIcon>
                                <ListItemText>API Endpoint</ListItemText>
                            </MenuItem>
                            <MenuItem
                                onClick={async () => {
                                    console.log('API Key');
                                    const apiKey = await api.cli.getApiKey();
                                    navigator.clipboard.writeText(apiKey);
                                    // hide the menu
                                    setMenuAnchorEl(null);
                                    // show api key message
                                    setShowApiKeySnackbar(true);
                                }}>
                                <ListItemIcon>
                                    <FingerprintSharpIcon />
                                </ListItemIcon>
                                <ListItemText>API Key</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                component="a"
                                href={`${URIs.Fofum}/login`}
                                target="_blank"
                                rel="noopener"
                                onClick={() => { setMenuAnchorEl(null); }}
                            >
                                <ListItemIcon>
                                    <GroupsIcon />
                                </ListItemIcon>
                                <ListItemText>Support Forum</ListItemText>
                            </MenuItem>
                            <MenuItem disabled>
                                <ListItemIcon>
                                    <LiveHelpIcon />
                                </ListItemIcon>
                                <ListItemText>Get Started / FAQ</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>Log Out</ListItemText>
                            </MenuItem>
                        </Menu>
                    </>
                </Toolbar>
            </AppBar >
            <Snackbar
                open={showApiKeySnackbar}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                message="API Key copied to clipboard."
                onClose={() => setShowApiKeySnackbar(false)}
            >
                <Alert
                    onClose={() => setShowApiKeySnackbar(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    API Key copied to clipboard!
                </Alert>
            </Snackbar>
            <InputModal
                title='Enter new API endpoint URL'
                show={showInputModal}
                defaultValue={apiEndpoint || ''}
                handleClose={() => setShowInputModal(false)}
                onEnter={(newApiEndpoint) => { if (newApiEndpoint !== apiEndpoint) setApiEndpoint(newApiEndpoint); }}
            />
            <AboutModal
                show={showAboutModal}
                handleClose={() => setShowAboutModal(false)}
            />
        </>
    );
}

// export default HeaderComponent;