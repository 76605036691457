import React, { useRef, useEffect } from 'react';

// bootstrap
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl'
import "bootstrap/dist/css/bootstrap.css";


const InputModal = ({
    title = 'Enter',
    handleClose = () => { },
    onEnter = () => { },
    defaultValue = '',
    show = false,
}) => {
    const inputRef = useRef(null);

    const handleEnter = () => {
        const valueToEnter = inputRef.current.value.trim();
        if ( valueToEnter !== '') {
            onEnter(valueToEnter);
        }
        handleClose();
    };

    const onKeyPress = (event) => {
        if (event.keyCode === 13) {
            handleEnter();
        }
    }

    useEffect(() => {
        inputRef.current && inputRef.current.focus();
        inputRef.current && inputRef.current.select();
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={() => { handleClose() }}
            keyboard={false}
        >
            <Modal.Body>
                <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup">{title}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl ref={inputRef} aria-describedby="inputGroup" onKeyDown={onKeyPress} defaultValue={defaultValue} />
                    <Button variant="primary" onClick={() => { handleEnter() }}>OK</Button>
                </InputGroup>
            </Modal.Body>
        </Modal>
    );
};

export default InputModal;