import { createMachine, assign } from "xstate";

export const machine =
    createMachine(
        {
            context: {
                brickId: null,
                newBrickId: null,
                showSaveBrickModal: false,
                showUpdateBrickUsageModal: false,
            },
            id: "Brick Editor - logic",
            initial: "empty_view",
            states: {
                empty_view: {
                    on: {
                        LOAD_BRICK: {
                            target: "view_brick",
                            guard: "brickId != null",
                            actions: {
                                type: "setBrickId",
                            },
                        },
                    },
                },
                view_brick: {
                    // entry: {
                    //     type: "loadBrick",
                    // },
                    always: {
                        actions: {
                            type: "loadBrick",
                        },
                    },
                    on: {
                        AMEND_BRICK: {
                            target: "amended_brick",
                        },
                        LOAD_BRICK: {
                            target: "view_brick",
                            actions: {
                                type: "setBrickId",
                            },
                        },
                        RESET_VIEW: {
                            target: "empty_view",
                        },
                    },
                },
                amended_brick: {
                    on: {
                        UNDO_CHANGES: {
                            target: "view_brick",
                        },
                        LOAD_BRICK: {
                            target: "save_brick",
                            actions: {
                                type: "setBrickId",
                            },
                            guard: "brickId != newBrickId",
                        },
                        SAVE_BRICK: [
                            {
                                target: "update_brick_usage",
                                guard: "iskBrickInUse",
                            },
                            {
                                target: "view_brick",
                                actions: {
                                    type: "saveBrick",
                                }
                            },
                        ],
                    },
                },
                save_brick: {
                    entry: assign({ showSaveBrickModal: true }),
                    exit: assign({ showSaveBrickModal: false }),
                    on: {
                        CANCEL: {
                            target: "amended_brick",
                        },
                        YES: [
                            {
                                target: "update_brick_usage",
                                guard: "iskBrickInUse",
                            },
                            {
                                target: "view_brick",
                                actions: {
                                    type: "saveBrick",
                                },
                            }
                        ],
                        NO: {
                            target: "view_brick",
                        },
                    },
                },
                update_brick_usage: {
                    entry: assign({ showUpdateBrickUsageModal: true }),
                    exit: assign({ showUpdateBrickUsageModal: false }),
                    on: {
                        UPDATE: {
                            target: "view_brick",
                            actions: [
                                {
                                    type: "updateUsages",
                                },
                            ],
                        },
                        CANCEL: {
                            // target: "view_brick",
                            target: "amended_brick",
                        },
                    },
                },
            },
        },
        {
            actions: {
                //   loadBrick: ({ context, event }) => {},
                //   saveBrick: ({ context, event }) => {},
                //   updateUsages: ({ context, event }) => {},
                // // store the brick Id to load in the context
                // setBrickId: assign({
                //     newBrickId: ({ event }) => event.brickId,
                // }),
            },
            actors: {},
            guards: {
                iskBrickInUse: ({ context, event }, params) => {
                    return false;
                },
                "brickId != null": ({ context, event }, params) => {
                    return false;
                },
                "brickId != newBrickId": ({ context, event }, params) => {
                    return false;
                },
            },
            delays: {},
        },
    );