import PropTypes from 'prop-types';

// MUI
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';

const DialogModal = ({
    title = 'Dialog',
    handleClose = () => { },
    onPrimary = () => { },
    onSecondary = () => handleClose(),
    onSecondary2 = () => { },
    primaryBtnLabel = 'OK',
    secondaryBtnLabel = 'Cancel',
    secondaryBtnLabel2 = null,
    beforeContentDescription = null,
    paddingTop,
    show = false, children }) => {

    // const handlePrimaryBtnClick = () => {
    //     onPrimary();
    // }

    // const handleSecondaryBtnClick = () => {
    //     onSecondary();
    // }

    return (
        <Dialog
            open={show}
            onClose={handleClose}
            sx={{ minHeight: '400px' }}
        >
            <DialogTitle>{title}</DialogTitle>
            {beforeContentDescription && (
                <DialogContent dividers>
                    <DialogContentText sx={{ width: '100%' }}>
                        {beforeContentDescription}
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogContent dividers sx={{ paddingTop, paddingBottom: `${paddingTop}` }}>
                {children}
            </DialogContent>
            <DialogActions>
                {secondaryBtnLabel2 && (<Button variant='outlined' onClick={onSecondary2}>{secondaryBtnLabel2}</Button>)}
                <Button variant='outlined' onClick={onSecondary}>{secondaryBtnLabel}</Button>
                <Button variant='contained' autoFocus onClick={onPrimary}>{primaryBtnLabel}</Button>
            </DialogActions>
        </Dialog >
    );
};

DialogModal.propTypes = {
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    onPrimary: PropTypes.func.isRequired,
    onSecondary: PropTypes.func,
    onSecondary2: PropTypes.func,
    primaryBtnLabel: PropTypes.string,
    secondaryBtnLabel: PropTypes.string,
    secondaryBtnLabel2: PropTypes.string,
    show: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
};

export default DialogModal;