import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';

import TreeNode from 'models/treeNode';
import ITreeNode from 'types/treeNode';
import AppStore from 'stores/app';
import { NodeType } from 'utils/const';

export enum TreeItemAction {
    None = '',
    New = 'new',
    Deleted = 'deleted',
    Updated = 'updated'
};

interface IdAction {
    id: string,
    action: TreeItemAction,
    node: ITreeNode | undefined
}

type StoreName = 'brick' | 'construction' | null;

export default class TreeStore {

    @observable
    nodesById = new Map<string, ITreeNode>()
        .set('', {
            id: '0xFF',
            text: 'Lodaing ...',
            node_type: NodeType.Item
        } as ITreeNode);

    @observable
    lastUpdatedNodeId = '';

    @observable
    lastUpdatedNodeIds = new Array<IdAction>();
    // lastUpdatedNodeIds = new Map<string, Action>().cl

    constructor(private store: AppStore, private canSubItemRename = true, private refStoreName: StoreName = null) {
        makeObservable(this);
    }

    @action
    load(nodes: ITreeNode[]) {
        this.nodesById.clear();
        nodes.forEach((node) => this.nodesById.set(node.id, new TreeNode(this.store, node)));
    }

    @action
    add(nodes: ITreeNode | ITreeNode[]) {
        const newNodes = Array.isArray(nodes) ? nodes : [nodes];
        this.lastUpdatedNodeIds = [];
        newNodes.forEach(node => {
            this.nodesById.set(node.id, new TreeNode(this.store, node));
            const parentNode = this.nodesById.get(node.parent_id);
            parentNode?.children.push(node.id);
            parentNode && this.nodesById.set(parentNode.id, parentNode);
            this.lastUpdatedNodeId = node.id;
            this.lastUpdatedNodeIds.push({ id: node.id, action: TreeItemAction.New, node });
        });
    }

    @computed
    get nodes(): ITreeNode[] {
        return Array.from(this.nodesById.values())
    }

    @computed
    get treeItems(): any {
        console.log('>>TreeStore::treeItems<<');
        const nodesArr = this.nodes;
        // find the root element of the tree
        const rootNode = nodesArr.find(node => node?.parent_id === undefined);
        // build the tree items object
        const newTreeItems =
            nodesArr.reduce(
                (acc, node) => {
                    const canRename =
                        !this.canSubItemRename && (node.node_type === NodeType.NodeSubItem || node.node_type === NodeType.SubItem)
                            ? false
                            : true;
                    return {
                        ...acc,
                        [node.id]: {
                            ...node,
                            // tree control attrs
                            index: node.id,
                            isFolder: (node.node_type & NodeType.Node) === NodeType.Node,
                            canMove: true,
                            canRename: canRename,
                        }
                    };
                },
                {
                    root: {
                        // ...rootNode,
                        index: 'root',
                        canMove: false,
                        isFolder: true,
                        canRename: false,
                        children: [rootNode?.id],
                        text: 'Root item',
                        data: 'Root item',
                    }
                }
            );
        console.log('NEW Tree', newTreeItems);
        return newTreeItems;
    }

    @action
    update(node: ITreeNode) {
        const prevNode = this.nodesById.get(node.id);
        // remove node from the parrent's children array 
        if (prevNode?.parent_id !== node?.parent_id) {
            // previous parent's children array
            const parentNode = this.nodesById.get(prevNode?.parent_id || '');
            const index = parentNode?.children.indexOf(node.id);
            if (index !== undefined && index > -1) {
                parentNode?.children.splice(index, 1);
            }
            // update new parent's children array
            const newParentNode = this.nodesById.get(node.parent_id);
            newParentNode?.children.push(node.id);
            newParentNode && this.nodesById.set(newParentNode.id, newParentNode);
        }
        this.nodesById.set(node.id, new TreeNode(this.store, node));
        this.lastUpdatedNodeId = node.id;
        this.lastUpdatedNodeIds = [];
        this.lastUpdatedNodeIds.push({ id: node.id, action: TreeItemAction.Updated, node });
    }

    @action
    moveNodes(nodes: ITreeNode[], targetNode: ITreeNode, parentIds: string[]) {
        const nodeIds = nodes.map(node => node.id);
        // update children arrays for the prev. parent node
        parentIds.forEach(id => {
            const parentNode = this.nodesById.get(id);
            if (parentNode) {
                parentNode.children = parentNode.children.filter(childId => !nodeIds.includes(childId));
                this.nodesById.set(id, parentNode);
            }
        });
        // update target node
        this.nodesById.set(targetNode.id, targetNode);
        nodes.forEach(node => {
            this.nodesById.set(node.id, node);
        });
    }

    @action
    delete(nodeIds: string[]) {
        console.log('delete', nodeIds);
        const referenceIds: string[] = [];
        this.lastUpdatedNodeIds = [];
        nodeIds.forEach(nodeId => {
            const node = this.nodesById.get(nodeId);
            if (node?.data?.reference_id) {
                referenceIds.push(node.data.reference_id);
            }
            const parentNode = this.nodesById.get(node?.parent_id || '');
            const index = parentNode?.children.indexOf(nodeId);
            if (index !== undefined && index > -1) {
                parentNode?.children.splice(index, 1);
            }
            this.nodesById.delete(nodeId);
            this.lastUpdatedNodeIds.push({ id: nodeId, action: TreeItemAction.Deleted, node });
        });
        if (this.refStoreName != null) {
            const referenceStore = this.refStoreName === 'brick' ? this.store.brick : this.store.construction;
            referenceIds.forEach(refId => { referenceStore.delete(refId) });
        }
    }
}