import * as Blockly from 'blockly/core';
import { Colors } from '../const';

// construction block color
const BASE_TEST_COLOR = Colors.BASE_TEST_COLOR; //'#2E476A';

Blockly.defineBlocksWithJsonArray([
    {
        "type": "base_test",
        "message0": "%1 %2 %3",
        "args0": [
            {
                "type": "field_input",
                "name": "TEST_NAME",
                "text": "Test Case"
            },
            {
                "type": "input_dummy"
            },
            {
                "type": "input_statement",
                "name": "TEST_CONTENT",
                "check": "Technology"
            }
        ],
        "colour": BASE_TEST_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
]);
