import * as Blockly from 'blockly/core';
import { Colors } from '../const';

// actions blocks colors
const ACTION_GETTER_COLOR = Colors.ACTION_GETTER_COLOR; //'#E1B16A';

function attrGetterValidator(action) {
    const sourceBlock = this.getSourceBlock();
    var attrNameInput = sourceBlock.getInput('ATTRIBUTE_NAME_INPUT');
    // remove field by default
    if (sourceBlock.getField('ATTRIBUTE_NAME')) {
        attrNameInput.removeField('ATTRIBUTE_NAME');
    }
    // add 'ATTRIBUTE_NAME' field when required
    if (action === 'ATTRIBUTE') {
        if (!sourceBlock.getField('ATTRIBUTE_NAME')) {
            attrNameInput.appendField(new Blockly.FieldTextInput('default'), 'ATTRIBUTE_NAME');
            attrNameInput.init();
        }
    }
}

const attrGetterJson = {
    "message0": "%1 %2 ==> %3",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "GETTER_TYPE",
            "options": [
                ['Get Text', 'TEXT'],
                ['Get Value', 'VALUE'],
                ['Get Attribute', 'ATTRIBUTE']
            ]
        },
        {
            "type": "input_dummy",
            "name": "ATTRIBUTE_NAME_INPUT"
        },
        // {
        //     "type": "input_value",
        //     "name": "ATTRIBUTE_NAME",
        //     "check": "String"
        // },
        {
            "type": "field_variable",
            "name": "VAR",
            "variable": ">attr"
        }
    ],
    "inputsInline": true,
    // "previousStatement": "Action",
    // "output": "Action",
    "nextStatement": "Action",
    "colour": ACTION_GETTER_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['attr_getter'] = {
    init: function () {
        this.jsonInit({ ...attrGetterJson, "output": "Action" });
        this.getField('GETTER_TYPE').setValidator(attrGetterValidator);
    }
};

Blockly.Blocks['attr_getter_stack'] = {
    init: function () {
        this.jsonInit({ ...attrGetterJson, "previousStatement": "Action" });
        this.getField('GETTER_TYPE').setValidator(attrGetterValidator);
    }
};