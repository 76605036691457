import { useEffect, useRef } from 'react';
import util from 'util';


// TODO: did not manage to force update/render the Blockly component;
// that is workaround which seems to do its job but would need to
// find the root cause of the incorrect behaviour
export const resizeWindow = () => {
    setTimeout(() => { //Start the timer
        window.dispatchEvent(new Event('resize'));
    }, 20);
}

export const useCombinedRefs = (...refs) => {
    const targetRef = useRef();

    useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;

            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        })
    }, [refs]);

    return targetRef;
}

const consoleFuncs = {
    log: console.log.bind(console),
    info: console.info.bind(console),
    warn: console.warn.bind(console),
    error: console.error.bind(console),
    debug: (console.debug || console.log).bind(console)
};


export function addConsolePreffix(fn) {
    Object.keys(consoleFuncs).forEach(function (k) {
        console[k] = function () {
            var s = typeof fn === 'function' ? fn() : fn;
            arguments[0] = util.format(s, arguments[0]);
            consoleFuncs[k].apply(console, arguments);
        };
    });
}