import * as Blockly from 'blockly/core';
import { Colors } from '../const';

// actions blocks colors
const ACTION_INPUT_COLOR = Colors.ACTION_INPUT_COLOR; //'#CE5A57';
// browser actions
const ACTION_BROWSER_COLOR = Colors.ACTION_BROWSER_COLOR; //'#138D90';

const actionOptions = [
    [
        "Click",
        "CLICK"
    ],
    [
        "DoubleClick",
        "DBL_CLICK"
    ],
    [
        "Hover",
        "HOVER"
    ]
];

Blockly.defineBlocksWithJsonArray([
    {
        "type": "action_type",
        "message0": "%1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "ACTION",
                "options": actionOptions,
            }
        ],
        "inputsInline": true,
        "nextStatement": "Action",
        "output": "Action",
        "colour": ACTION_INPUT_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
    {
        "type": "action_stack",
        "message0": "%1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "ACTION",
                "options": actionOptions,
            }
        ],
        "inputsInline": true,
        "previousStatement": "Action",
        "nextStatement": "Action",
        "colour": ACTION_INPUT_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
]);

// validator function for 'action_text' and 'action_text_stack' blocks
function actionTextValidate(action) {
    const sourceBlock = this.getSourceBlock();
    if (action === 'CLEAR_VALUE') {
        sourceBlock.removeInput('TEXT', true);
    }
    else {
        if (!sourceBlock.getInput('TEXT')) {
            const newTextInput = sourceBlock.appendValueInput('TEXT');
            newTextInput.setCheck('String');
            const shadowXml = Blockly.utils.xml.textToDom('<shadow type="text"><field name="TEXT">text</field></shadow>');
            newTextInput && newTextInput.setShadowDom(shadowXml);
        }
    }
}

const actionTextJson = {
    "message0": "%1 %2 %3",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "ACTION",
            "options": [
                [
                    "Set Value",
                    "SET_VALUE"
                ],
                [
                    "Add Value",
                    "ADD_VALUE"
                ],
                [
                    "Clear Value",
                    "CLEAR_VALUE"
                ]
            ]
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_value",
            "name": "TEXT",
            "check": "String"
        }
    ],
    "inputsInline": true,
    // "previousStatement": "Action",
    // "output": "Action",
    "nextStatement": "Action",
    "colour": ACTION_INPUT_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['action_text_stack'] = {
    init: function () {
        this.jsonInit({ ...actionTextJson, "previousStatement": "Action" });
        this.getField('ACTION').setValidator(actionTextValidate);
    }
};

Blockly.Blocks['action_text'] = {
    init: function () {
        this.jsonInit({ ...actionTextJson, "output": "Action" });
        this.getField('ACTION').setValidator(actionTextValidate);
    }
};

Blockly.Blocks['action_constrained_stack'] = {
    init: function () {
        this.jsonInit({
            "message0": "Constraint %1 %2",
            "args0": [
                {
                    "type": "field_dropdown",
                    "name": "CONST_TYPE",
                    "options": [
                        [
                            "By Index",
                            "BY_INDEX"
                        ],
                        [
                            "By Content",
                            "BY_CONTENT"
                        ]
                    ]
                },
                {
                    "type": "input_value",
                    "name": "CONSTRAINT",
                    "check": "Number"
                }
            ],
            "inputsInline": true,
            "nextStatement": "Action",
            "output": "Action",
            "colour": ACTION_INPUT_COLOR,
            // "style": "math_blocks"
        });

        const field = this.getField('CONST_TYPE');
        field.setValidator(this.validate);
    },

    validate: function (action) {
        const sourceBlock = this.getSourceBlock();
        const constInput = sourceBlock.getInput('CONSTRAINT');
        constInput.setCheck(['Number', 'String']);
        if (action === 'BY_INDEX') {
            const shadowXml = Blockly.utils.xml.textToDom('<shadow type="math_number"><field name="NUM">0</field></shadow>');
            constInput && constInput.setShadowDom(shadowXml);
            constInput.setCheck('Number');
        }
        else if (action === 'BY_CONTENT') {
            const shadowXml = Blockly.utils.xml.textToDom('<shadow type="text"><field name="TEXT">text</field></shadow>');
            constInput && constInput.setShadowDom(shadowXml);
            constInput.setCheck('String');
        }
    }
};



/**
 * 
 */

const valueTextJson = {
    "message0": "%1 %2 %3",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "ACTION",
            "options": [
                [
                    "Set Value",
                    "SET_VALUE"
                ],
                [
                    "Add Value",
                    "ADD_VALUE"
                ],
                [
                    "Clear Value",
                    "CLEAR_VALUE"
                ]
            ]
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_value",
            "name": "TEXT",
            "check": "String"
        }
    ],
    "inputsInline": true,
    // "previousStatement": "Action",
    "output": null,
    // "nextStatement": "Action",
    "colour": ACTION_BROWSER_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['value_text'] = {
    init: function () {
        this.jsonInit({ ...valueTextJson, /*"output": "Action"*/ });
        this.getField('ACTION').setValidator(actionTextValidate);
    }
};