import { vmtBaseJSGenerator, baseForBlock } from '../base/baseJavascript';

// NOTE: here is the !raw-loader! used
// See following:
//   - raw-loader: https://v4.webpack.js.org/loaders/raw-loader/
//   - Webpack Loaders: https://webpack.js.org/concepts/loaders/
// eslint-disable-next-line import/no-webpack-loader-syntax
import vmtHelperCode from '!raw-loader!./func_framework_ref';

export const forBlock = Object.create(null);
export const vmtJSGenerator = Object.create(null);

// assign base/common generator implementation
Object.assign(vmtJSGenerator, vmtBaseJSGenerator);
Object.assign(forBlock, baseForBlock);

/**
 * Framework code (WDIO specific)
 * (Vavanya specific)
 * @returns {string}
 */
vmtJSGenerator.frameworkCode = () => { return { wdio: vmtHelperCode }; };

const wdioImportsCode = [
    '// WebdriverIO',
    `import { remote } from 'webdriverio';`,
    `import { dirname } from 'path';`,
    `import { fileURLToPath } from 'url';`,
    `const __dirname = dirname(fileURLToPath(import.meta.url));`,
    '',
    `import * as vmtFw from './_func_frameworks_/wdio_func_fw.js';`,
    ''
].join('\n');

/**
 * Imports code (WDIO specific)
 * (Vavanya specific)
 * @returns {Object}
 */
vmtJSGenerator.importsCode = () => wdioImportsCode;