// Rect
import { useState, /*useEffect,*/ useCallback, useRef/*, memo*/ } from 'react';

// Blockly
import * as Blockly from 'blockly/core';
import 'blockly/blocks';

// Types required for JSDoc comments
// eslint-disable-next-line no-unused-vars
import { Workspace, Block } from 'blockly';

import { useSnackbar } from 'notistack';

// Brick Factory utils
import { createBlock, createBlockData, createField, createStatement, createControlBlock } from './blockly_stuff/xml';

// Context
import { useAppContext } from "app-context";

const emptyInitialXml = '<xml/>';

const useBrickFactory = () => {

    const { api, store } = useAppContext();
    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();
    const [originalBrick, setOriginalBrick] = useState(null);
    const [origWsXml, setOrigWsXml] = useState();

    const brickId2EditRef = useRef(null);
    const nextBrickId2EditRef = useRef(null);

    /**
     * Load brick into the BF workspace by VMT brick ID
     * @param {Workspace|null} workspace - Brick Factory workspace to load the brick
     * @param {string|null} vmtBlockId - brick id in the store/DB
     */
    const loadBrickById = useCallback((/**@type {Workspace|null}*/workspace, /**@type {string|null}*/vmtBrickId) => {
        if (!workspace) return;
        Blockly.Events.disable();
        try {
            const { current: currentBrickIdInEdit } = brickId2EditRef;
            if (vmtBrickId !== null) {
                const brick = store.brick.byId(vmtBrickId);
                console.log('brickData', brick);
                if (!brick) {
                    setOriginalBrick(null);
                    loadBrickById(workspace, null);
                    return;
                }
                setOriginalBrick(brick);
                //----------------------------------------------------------------
                // reconstruct Brick Factory worspace from the given brick
                // (Brick Editing Mode)
                //----------------------------------------------------------------
                // XML root node
                const initialXml = Blockly.utils.xml.createElement('xml');

                // add empty factory_base block
                let nextElement = createBlock('factory_base', false, false);

                const data = Blockly.utils.xml.createElement('data');
                data.appendChild(Blockly.utils.xml.createTextNode(
                    JSON.stringify(
                        createBlockData({
                            id: vmtBrickId,
                            name: brick.name,
                            anchor: '<brick_selector>',
                            type: 'brick'
                        }))));
                nextElement.appendChild(data);

                initialXml.appendChild(nextElement);
                // brick NAME
                nextElement.appendChild(createField('NAME', brick.name)); // <== brick name

                // add input statement block
                nextElement = nextElement.appendChild(createStatement('INPUTS'));

                brick.controls.forEach((field) => {
                    console.log('Field', field);
                    nextElement = nextElement.appendChild(createControlBlock(field));
                    nextElement = nextElement.appendChild(Blockly.utils.xml.createElement('next'));
                });
                Blockly.Xml.clearWorkspaceAndLoadFromXml(initialXml, workspace);

                // // add new "next possible" disabled control
                // // and connect the control to prev. control
                // const newControlBlock = brickFactoryWorkspace.newBlock('control_generic');
                // newControlBlock['nextControl'] = true;
                // block.nextConnection.connect(newControlBlock.previousConnection);
                // newControlBlock.initSvg();
                // newControlBlock.render();

                if (vmtBrickId !== null && vmtBrickId !== currentBrickIdInEdit) {
                    workspace.clearUndo();
                }
                // setBrickMode({
                //     state: ModeStates.EDIT_STATE,
                //     touched: false
                // });
            }
            else {
                // New brick
                const xml = Blockly.utils.xml.textToDom(emptyInitialXml);
                // newXml = xml;
                Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, workspace);
                workspace.clearUndo();
            }
            const currentWsXml = Blockly.Xml.workspaceToDom(workspace);
            const xmlText = Blockly.Xml.domToText(currentWsXml);
            setOrigWsXml(xmlText);
            brickId2EditRef.current = vmtBrickId;
            nextBrickId2EditRef.current = null;
        }
        finally {
            Blockly.Events.enable();
        }
    }, [store.brick]);

    /**
     * Returs the list of children elements (controls) for the given block 
     * @param {Blockly.Block} rootBlock 
     * @returns {IBrick[]}
     */
    const getControlsData = (rootBlock) => {
        // iterate through the controls
        let contentsBlock = rootBlock.getInputTargetBlock('INPUTS');
        let controls = [];
        while (contentsBlock) {
            if (!contentsBlock.disabled && !contentsBlock.getInheritedDisabled()) {
                const blockData = JSON.parse(contentsBlock.data);
                console.log('blockData', blockData);

                const controlData = {
                    type: blockData.type,
                    name: blockData.name,
                    ID: blockData.ID,
                };

                if (blockData.id) {
                    controlData._id = blockData.id;
                }
                console.log('controlData', controlData);
                controls.push(controlData);
            }
            contentsBlock = contentsBlock.nextConnection &&
                contentsBlock.nextConnection.targetBlock();
        }
        return controls;
    };

    /**
     * Saves the brick for the given Brick Factory workspace
     * @param {Blockly.Workspace} workspace - Brick Factory workspace
     */
    const saveBrick = useCallback(async (workspace) => {
        console.log('Save');

        const saveBrick_ = async () => {
            console.log('saveBrick_');
            //const rootBlock = getRootBlock();
            let rootBlock = null;
            const blocks = workspace.getTopBlocks(false);
            for (let i = 0, block; !!(block = blocks[i]); i++) {
                if (block.type === 'factory_base') {
                    rootBlock = block;
                    break;
                }
            }
            if (!rootBlock) { return; }

            const brickData = JSON.parse(rootBlock.data);
            console.log('saveBrick_: brickData', brickData)
            const brickJson = {
                brick_type: brickData.type, //brickType,
                brick_caption: brickData.name, // brickCaption,
                controls: [...getControlsData(rootBlock)]
            };

            const updatedBrick = await api.brick.update(brickData.id, brickJson);
            if (updatedBrick) {
                console.log('updatedBrick', updatedBrick);
                enqueueSnackbar('Brick was successfully updated', { variant: 'success', persist: false });
                const { current: nextbrickId2Edit } = nextBrickId2EditRef;
                if (nextbrickId2Edit !== null) {
                    loadBrickById(workspace, nextbrickId2Edit);
                }
            }
            else {
                enqueueSnackbar('Cannot update brick', { variant: 'error', persist: true });
            }
        };
        await saveBrick_();
    }, [api.brick, enqueueSnackbar, loadBrickById]);

    return {
        loadBrickById,
        saveBrick,
        originalBrick,
        origWsXml,
    };
};

export default useBrickFactory;