// React
import { useEffect } from 'react';

// MUI
import { loadCSS } from 'fg-loadcss';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// MobX
import { observer } from 'mobx-react-lite';

// VMT
import HeaderComponent from 'components/HeaderComponent';
import VAppLayoutManager from 'components/VAppLayoutManager';
import { useAppContext } from "app-context";
import useBlocklyEvents from 'utils/hooks/useBlocklyEvents';
import useTreeStoresSynchronizer from 'utils/hooks/useTreeStoresSynchronizer';


// VMT common
import { URIs } from 'utils/const'
import { resizeWindow } from 'utils/misc';

import { apiEndpointStore, useStore } from 'stores';

// const ME = Components.V_APP.key;

export const VApp = observer(() => {
    /*const { basementWS } = */useBlocklyEvents();
    const { api, session: { startup, theme } } = useAppContext();
    const [endpoint] = useStore(apiEndpointStore);
    useTreeStoresSynchronizer();

    useEffect(() => {
        console.log("useEffect()");
        const load = async () => {
            try {
                await api.brick.getAll();
                await api.brickTree.getAll();
                await api.constructionTree.getAll();
                await api.streetTree.getAll();
                await api.construction.getAll();
            } finally {
                startup.setLoading(false);
                // TODO: did not manage to force update/render the Blockly component;
                // that is workaround which seems to do its job but would need to
                // find the root cause of the incorrect behaviour
                setTimeout(() => resizeWindow(), 1000);
            }
        };
        load();

        // load Font Awesome
        const node = loadCSS(
            URIs.FontAwesome,
            // Inject before JSS
            document.querySelector('#font-awesome-css') || document.head.firstChild,
        );

        return () => {
            node.parentNode.removeChild(node);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log(`API endpoint CHANGED '${endpoint}'`);
        // reload the app if API endpoint has changed
        if (endpoint === apiEndpointStore.defaultEndpoint) return;
        window.location.reload();
    }, [endpoint]);

    useEffect(() => {
        console.log('THEME', theme.themeName);
        // set up a theme
        document.querySelector('body').setAttribute('data-theme', theme.themeName);
    }, [theme.themeName]);

    if (startup.loading) {
        return (
            <LinearProgress />
        );
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {/* <Container fluid className={"px-0 " + css(styles.appContainer)}> */}
            <Grid container>
                {/* <Row className={"mx-0 " + css(styles.appHeader)}> */}
                <Grid item xs={12}>
                    <HeaderComponent />
                </Grid>
                <VAppLayoutManager />
            </Grid>
        </Box>
    );
});
