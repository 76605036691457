// React
import { useState, useEffect } from 'react';

import CodePrettifier, { vmtLogLanguage, vmtLogTheme } from 'components/CodePrettifier';
import { useAppContext, BroadcastType } from 'app-context';

// VMT common
import { Components } from 'utils/const'

import { dataBusStore, useStore } from 'stores';

export const ExecutionLogCodePrettifier = () => {
    const [logContentToScroll, setLogContentToScroll] = useState('');
    const [logCode, setLogCode] = useState('');
    const [commandText, setCommandText] = useState({ command: '' });
    const [dataBus] = useStore(dataBusStore);
    const { dataBroadcaster: { data: broadcastData } } = useAppContext();

    useEffect(() => {
        // synch the basement editor and execution result
        if (dataBus?.to === Components.V_APP.key) {
            if (dataBus.from === Components.BASEMENT.key) {
                setLogContentToScroll(dataBus.content.blockId);
            }
        }
    }, [dataBus]);

    useEffect(() => {
        // console.log('>>>> broadcastData', broadcastData.executionLogState);
        if (broadcastData.action?.type === BroadcastType.AppendExecutionLog) {
            const executionState = broadcastData.executionLogState;
            switch (executionState.status) {
                case 'Connected':
                    setCommandText({ command: 'VMT_CLEAR_EXECUTION_LOG' });
                    // setLogCode('');
                    break;
                case 'Error':
                    setLogCode('[LOCAL RUNNER IS NOT DETECTED]\n');
                    break;
                case 'Idle':
                    setCommandText({ command: 'VMT_REVEAL_LAST_LINE' });
                    break;
                case 'Running':
                    setCommandText({ command: 'VMT_REVEAL_LAST_LINE' });
                    setLogCode(executionState.log.join(''));
                    break;
                default:
                    break;
            }
        }
    }, [broadcastData]);

    return (
        <CodePrettifier
            code={logCode}
            // lineToAppend={consoleText}
            vmtCommand={commandText}
            contentToScroll={logContentToScroll}
            language={vmtLogLanguage}
            theme={vmtLogTheme}
            options={{
                readOnly: true,
                lineNumbers: 'off',
                glyphMargin: false,
                folding: false,
                // Undocumented, see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                minimap: {
                    enabled: false,
                }
            }}
        />
    );
}