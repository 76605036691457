import { vmtBaseJSGenerator, baseForBlock } from '../base/baseJavascript';

// NOTE: here is the !raw-loader! used
// See following:
//   - raw-loader: https://v4.webpack.js.org/loaders/raw-loader/
//   - Webpack Loaders: https://webpack.js.org/concepts/loaders/
// eslint-disable-next-line import/no-webpack-loader-syntax
import vmtHelperCode from '!raw-loader!./func_framework_ref';

export const forBlock = Object.create(null);
export const vmtJSGenerator = Object.create(null);

// assign base/common generator implementation
Object.assign(vmtJSGenerator, vmtBaseJSGenerator);
Object.assign(forBlock, baseForBlock);

/**
 * Framework code (Playwright specific)
 * (Vavanya specific)
 * @returns {string}
 */
vmtJSGenerator.frameworkCode = () => { return { playwright: vmtHelperCode }; };

const pwImportsCode = [
    '// !!! WORK-IN-PROGRESS !!! Playwright',
    `const { remote } = require('webdriverio');`,
    '// workaround for Node v17.x and higher',
    `const dns = require('dns');`,
    '// workaround for Node v17.x and higher',
    `dns.setDefaultResultOrder('ipv4first');`,
    '',
    'const setBrowserCtx = (browser) => browserObject.browser = browser;',
    '',
].join('\n');

/**
 * Imports code (Playwright specific)
 * (Vavanya specific)
 * @returns {Object}
 */
vmtJSGenerator.importsCode = () => pwImportsCode;