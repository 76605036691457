// React
import { useState, useEffect } from 'react';

import CodePrettifier from 'components/CodePrettifier';
import useBlocklyEvents from 'utils/hooks/useBlocklyEvents';

// VMT common
import { Components } from 'utils/const'

import { dataBusStore, useStore } from 'stores';

export const JSCodePrettifier = () => {
    const [jsCode, setJsCode] = useState('');
    const { constructionDetails } = useBlocklyEvents();
    const [logContentToScroll, setLogContentToScroll] = useState('');
    const [dataBus] = useStore(dataBusStore);

    useEffect(() => {
        if (dataBus?.to && dataBus.from === Components.BASEMENT.key) {
            setLogContentToScroll(dataBus.content.blockId);
        }
    }, [dataBus]);

    useEffect(() => {
        if (constructionDetails) {
            let bricksCode = '';
            let frameworksCode = '';
            const buildingBlocksCode = constructionDetails.buildingBlocksCode;
            const frameworkCode = constructionDetails.frameworkCode;

            Object.keys(buildingBlocksCode).forEach(brickId => bricksCode += buildingBlocksCode[brickId]);
            Object.keys(frameworkCode).forEach(fwId => frameworksCode += frameworkCode[fwId]);

            const completeCode = [
                constructionDetails.importsCode,
                constructionDetails.constructionCode,
                bricksCode,
                frameworksCode
            ].join('\n');

            setJsCode(completeCode)
        }
    }, [constructionDetails]);

    return (
        <CodePrettifier code={jsCode} contentToScroll={logContentToScroll} />
    );
}