// React
import { useState, useEffect, useRef } from 'react';

import { useSnackbar } from 'notistack';
// MUI
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { red } from '@mui/material/colors';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BrickItemIcon from '@mui/icons-material/AutoAwesomeMosaic';
import RenameIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';

// MobX
import { observer } from 'mobx-react-lite';

import { useAppContext } from "app-context";
import { useContextMenu } from 'utils/hooks/useContextMenu';

// VMT components
import VmtTree from 'components/VmtTree';
import TableDialogModal from 'components/Modals/TableDialogModal';
import { TreeActions, CRUD, ModeStates, Components, ContextMenuItems, NodeType } from 'utils/const';

import {
    selectedBrickIDsStore, /*selectedBrickTreeNodeStore,*/
    brickFactoryDataStore, brickId2EditStore, dataBusStore, useStore
} from '../../stores';

const IconColor = red['A200'];
const ME = Components.BRICK_TREE.key;

export const BrickTreeComponent = observer((/*props*/) => {
    console.log('<BrickTreeComponent>');
    const { api, store } = useAppContext();
    const { contextMenu, anchorPosition, handleContextMenu, handleClose } = useContextMenu();
    /** @type {React.MutableRefObject<import('react-complex-tree').TreeRef>} */
    const treeRef = useRef();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const itemsToDeleteInfoRef = useRef(null);
    const treeItemsToDeleteCallbackRef = useRef(() => { });

    const [selectedTreeItem, setSelectedTreeItem] = useState(null);
    const [focusedItem, setFocusedItem] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    // stores
    const [dataToProcess, setDataToProcess] = useStore(brickFactoryDataStore);
    const [, setSelectedBrickIDs] = useStore(selectedBrickIDsStore);
    const [, setBrickId2Edit] = useStore(brickId2EditStore);
    const [dataBus/*, setDataBusContent*/] = useStore(dataBusStore);

    const [selectedBrickTreeNode, setSelectedBrickTreeNode] = useState(null);

    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();
    const closeDeleteModal = () => setShowDeleteModal(false);

    const modalHandleDelete = () => {
        treeItemsToDeleteCallbackRef.current();
        closeDeleteModal();
    }

    // scroll to the brick in the the tree
    useEffect(() => {
        if (dataBus?.to === ME && dataBus?.content?.brickId !== null) {
            const brickId = dataBus.content.brickId;
            console.log('dataBus', dataBus, brickId);
            console.log('store.brickTree.nodes', store.brickTree.nodes);
            // find node for given brick id
            const node = store.brickTree.nodes.find(node => node.data?.reference_id === brickId);
            console.log('node', node);
            node && setSelectedTreeItem({ id: node.id });
            // "consume" the data from the bus
            // setDataBusContent({ to: ME, from: ME, content: { brickId: null } });
        }
    }, [dataBus, /*setDataBusContent,*/ store.brickTree.nodes]);

    useEffect(() => {
        const { current: treeRef_ } = treeRef;
        if (treeRef_ && selectedTreeItem) {
            let itemId = selectedTreeItem.id;
            // expand the tree to reveal the node
            while (true) {
                const node = store.brickTree.nodesById.get(itemId);
                if (node.parent_id === undefined) break;
                itemId = node.parent_id;
                treeRef_.expandItem(itemId);
            }
            treeRef_.focusItem(selectedTreeItem.id);
            treeRef_.selectItems([selectedTreeItem.id]);
        }
        // eslint-disable-next-line
    }, [selectedTreeItem]);

    const apiCreateBrickTreeNode = async (treeNodeJson) => {
        const node = await api.brickTree.createEntry(treeNodeJson);
        setSelectedTreeItem({ id: node.id });
        treeRef.current.startRenamingItem(node.id);
    };

    const apiBricksCRUD = async (action, brickId, treeNode, brickData = null) => {
        switch (action) {
            case CRUD.Create:
                console.log('bricks.create', action, brickId, treeNode, brickData);
                const newBrick = await api.brick.create(brickData);
                if (newBrick) {
                    // add brick to the brick tree directory
                    const newTreeNodeItem = {
                        parent_id: treeNode?.id || selectedBrickTreeNode.id,
                        node_type: NodeType.Item,
                        text: newBrick.name,
                        data: {
                            reference_id: newBrick.id,
                        }
                    };
                    console.log(newTreeNodeItem);
                    apiCreateBrickTreeNode(newTreeNodeItem);
                    enqueueSnackbar('Brick was successfully created', { variant: 'success', persist: false });
                }
                else {
                    enqueueSnackbar('Cannot create brick', { variant: 'error', persist: true });
                }
                break;
            case CRUD.Update:
                console.log('bricks.update', action, brickId, treeNode, brickData);
                const updatedBrick = await api.brick.update(brickId, brickData);
                if (updatedBrick) {
                    console.log('updatedBrick', updatedBrick);
                    enqueueSnackbar('Brick was successfully updated', { variant: 'success', persist: false });
                }
                else {
                    enqueueSnackbar('Cannot updated brick', { variant: 'error', persist: true });
                }
                break;
            default:
                break;
        }
    };

    const apiUpdateBrickTree = async (action, id, dataToUpdate) => {
        try {
            const prevNodeText = store.brickTree.nodesById.get(id).text;
            const node = await api.brickTree.update(id, dataToUpdate);
            console.log('api.brickTree.update', node);
            if (node.node_type === NodeType.Item && dataToUpdate?.text && dataToUpdate.text !== prevNodeText) {
                // update Brick record
                const brickId = node.data.reference_id;
                apiBricksCRUD(CRUD.Update, brickId, null, { brick_caption: dataToUpdate.text });
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const onSelectItems = (itemId/*, treeId*/) => {
        const item = store.brickTree.nodesById.get(itemId);
        console.log('Tree item: ', item);
        console.log('----------------');
        let brickIds = [];
        if (item.node_type === NodeType.Item) {
            brickIds = [item.data.reference_id];
        }
        else {
            item.children.forEach(childNodeId => {
                const childNode = store.brickTree.nodesById.get(childNodeId);
                if (childNode?.node_type === NodeType.Item) {
                    brickIds.push(childNode.data.reference_id);
                }
            });
        }
        console.log(brickIds);
        setSelectedBrickIDs([...brickIds]);
        setBrickId2Edit(brickIds.length > 0 ? brickIds[0] : null);
        setSelectedBrickTreeNode(
            item.node_type === NodeType.Node ? item : store.brickTree.nodesById.get(item.parent_id)
        );
    };

    const getAllChildItems = (parentNode) => {
        let childItems = []
        parentNode.children.forEach(nodeId => {
            const node = store.brickTree.nodesById.get(nodeId);
            if (node) {
                childItems.push(node);
                if (node.children.length > 0) {
                    const subNodes = getAllChildItems(node);
                    childItems.push(...subNodes);
                }
            }
        });
        return childItems;
    };

    const onTreeChange = (itemData, action) => {
        console.log('onTreeChange: ', action, itemData);

        switch (action) {
            case TreeActions.Rename:
                apiUpdateBrickTree(TreeActions.Rename, itemData.id, { text: itemData.text });
                break;
            case TreeActions.Move:
                // change parent_id
                apiUpdateBrickTree(TreeActions.Move, itemData.id, { parent_id: itemData.parent_id });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (dataToProcess === null) return;
        console.log(dataToProcess);
        switch (dataToProcess.mode.state) {
            case ModeStates.NEW_STATE:
                apiBricksCRUD(CRUD.Create, null, dataToProcess.nodeId, dataToProcess.dataToStore);
                setDataToProcess(null);
                break;
            case ModeStates.EDIT_STATE:
                apiBricksCRUD(CRUD.Update, dataToProcess.brickId, null, dataToProcess.dataToStore);
                setDataToProcess(null);
                break;
            default:
                console.error(`Unknown brick mode state: ${dataToProcess.mode.state}`);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToProcess, /*apiBricksCRUD,*/ setDataToProcess]);

    const onMenuItemSelect = (menuItemText) => {
        return () => {
            handleClose();
            console.log('onMenuItemSelect', menuItemText);
            // console.log('onMenuItemSelect', menuXY.selectedItem);
            const treeNode = focusedItem;
            switch (menuItemText) {
                case ContextMenuItems.NewNode:
                    apiCreateBrickTreeNode({
                        parent_id: treeNode.id,
                        node_type: NodeType.Node,
                        text: 'New Node #1'
                        // no data.reference_id
                    });
                    break;
                case ContextMenuItems.RenameNode:
                    // setItemToEdit(treeNode);
                    treeRef.current.startRenamingItem(focusedItem.id);
                    break;
                case ContextMenuItems.DeleteNode:
                    // prepare list of item to delete
                    const childrenToDelete = [];
                    console.log('selectedItems', selectedItems);
                    let treeItemIdsToDelete = selectedItems.length <= 1 ? [focusedItem.id] : selectedItems;
                    treeItemIdsToDelete.forEach(nodeId => {
                        childrenToDelete.push(...getAllChildItems(store.brickTree.nodesById.get(nodeId)));
                    });
                    console.log(childrenToDelete);
                    treeItemIdsToDelete = [...treeItemIdsToDelete, ...childrenToDelete.map(item => item.id)];
                    treeItemIdsToDelete = [...new Set(treeItemIdsToDelete)];
                    console.log(treeItemIdsToDelete);

                    // 'calculate' bricks' usage
                    const brickIds = [];
                    treeItemIdsToDelete.forEach(treeId => {
                        const brickTreeItem = store.brickTree.nodesById.get(treeId);
                        if (brickTreeItem.data) {
                            brickIds.push(brickTreeItem.data.reference_id);
                        }
                    });
                    console.log('brickIds', brickIds);
                    const usages = store.construction.byBricksUsages(brickIds);

                    const treeItemsToDeleteWithDeps = [];

                    treeItemIdsToDelete.forEach(itemId => {
                        const brickTreeItem = store.brickTree.nodesById.get(itemId);
                        // return { ...brickTreeItem };
                        if (brickTreeItem.node_type === NodeType.Node) {
                            treeItemsToDeleteWithDeps.push({ ...brickTreeItem, info: 'Folder', cellStyle: { color: IconColor } });
                        }
                        else {
                            const brickId = brickTreeItem.data.reference_id;
                            const constrs = usages.get(brickId);
                            if (constrs.length > 0) {
                                constrs.forEach(constr => {
                                    treeItemsToDeleteWithDeps.push({ ...brickTreeItem, id: `${brickTreeItem.id}-${constr.id}`, info: constr.workspace_name });
                                })
                            }
                            else {
                                treeItemsToDeleteWithDeps.push({ ...brickTreeItem, info: 'no references' });
                            }
                        }
                    });
                    itemsToDeleteInfoRef.current = treeItemsToDeleteWithDeps;
                    console.log(treeItemsToDeleteWithDeps);

                    const deleteItems = async () => {
                        await api.brickTree.delete(treeItemIdsToDelete);
                        setSelectedItems([]);
                        // setFocusedItem(null);
                    };
                    treeItemsToDeleteCallbackRef.current = deleteItems;
                    setShowDeleteModal(true);

                    break;
                case ContextMenuItems.NewBrick:
                    // create new brick payload
                    const brickData = {};
                    brickData.brick_type = 'brick';
                    brickData.brick_caption = 'brick_name';
                    brickData.controls = [];
                    apiBricksCRUD(CRUD.Create, null, treeNode, brickData);
                    break;
                default:
                    break;
            }
        }
    };

    const isNodeSelected = () => focusedItem?.node_type === NodeType.Node;

    return (
        <>
            <div
                onContextMenu={handleContextMenu}
            >
                <VmtTree
                    treeId={'vmt-bricks-tree'}
                    treeLabel={'BrickTree'}
                    // itemArray={store.brickTree.nodes}
                    treeItems={store.brickTree.treeItems}
                    onFocusItem={(item) => { console.log("onFocusItem", item); setFocusedItem(item); }}
                    onDrop={(itemIds, targetItemId, position) => {
                        console.log('onDrop', itemIds, targetItemId, position);
                        api.brickTree.moveNodes(itemIds, targetItemId, position);
                    }}
                    onSelectItems={(items) => { onSelectItems(items[0]); setSelectedItems([...items]); }}
                    onRenameItem={(item, newName) => onTreeChange({ ...item, text: newName }, TreeActions.Rename)}
                    onDeleteKeyPressed={onMenuItemSelect(ContextMenuItems.DeleteNode)}
                    ref={treeRef}
                    color={IconColor}
                    ItemIcon={BrickItemIcon}
                />
            </div >
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
            >
                {isNodeSelected()
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NewNode)} dense>
                        <ListItemIcon>
                            <FolderIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.NewNode}</ListItemText>
                    </MenuItem>}
                {isNodeSelected()
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NewBrick)} dense>
                        <ListItemIcon>
                            <BrickItemIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.NewBrick}</ListItemText>
                    </MenuItem>}
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.RenameNode)} dense>
                    <ListItemIcon>
                        <RenameIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.RenameNode}</ListItemText>
                </MenuItem>
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.DeleteNode)} dense>
                    <ListItemIcon>
                        <DeleteForeverIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.DeleteNode}</ListItemText>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NodeDetails)} dense disabled>
                    <ListItemIcon>
                        <InfoIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.NodeDetails}</ListItemText>
                </MenuItem>
            </Menu>
            {/* Delete bricks's entries modal*/}
            <TableDialogModal
                title='Deleting...'
                tableHeaderItems={['Brick Name', 'Type/Usage']}
                tableContentItems={itemsToDeleteInfoRef.current === null ? [] : itemsToDeleteInfoRef.current}
                fieldNames={['id', 'text', 'info']}
                show={showDeleteModal}
                handleClose={closeDeleteModal}
                primaryBtnLabel='Delete'
                onPrimary={modalHandleDelete}
            />
        </>
    );
});