import { Generator, VARIABLE_CATEGORY_NAME, utils } from 'blockly/core';
import { Order } from 'blockly/javascript';

// Types required for JSDoc comments
// eslint-disable-next-line no-unused-vars
import { Workspace, Block } from 'blockly';

export const vmtBaseJSGenerator = Object.create(null);
export const baseForBlock = Object.create(null);

export const vmtBrickPrefix = 'vmt_brick_';

/**
 * @typedef {Object} ControlInfo
 * @property {string} id
 * @property {string} anchor
 */
/**
 * 
 * @param {Block} brick 
 * @returns {ControlInfo[]}
 */
const getControlsInfoFromData = (brick) => {
    // get list of controls for the block
    const childBlocks = brick.getChildren();
    let controlsData = [];
    let controls = [];
    if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
        const children = childBlocks[0].getDescendants();
        controls = children.filter(({ type }) => type.startsWith('vmt_control'));
        controls.forEach(control => {
            const controlData = JSON.parse(control.data);
            controlsData.push({ id: controlData.id, anchor: controlData.ID });
        });
    }
    return controlsData;
};

/**
 * Generate the page object code from the given block type
 * @param {string} vmtBrickId 
 * @param {Block} brick 
 * @returns 
 */
const getPOCode = (brick) => {
    const { id: vmtBrickId } = JSON.parse(brick.data);
    let code = [
        '',
        `// Page object definition for '${vmtBrickPrefix}${vmtBrickId}'`,
        `export const ${vmtBrickPrefix}${vmtBrickId} = {`,
        '  /**',
        '   * define elements',
        '   */'
    ].join('\n');
    const controls = getControlsInfoFromData(brick);
    controls && controls.forEach(control => code += `\n  '${control.id}': \`${control.anchor}\`,`);
    code += `\n}; // end of page object definition\n`;
    return code;
}

/**
 * Generate page object definitions for construction within workspace
 * (Vavanya specific)
 * @param {Workspace} workspace 
 * @returns {string}
 */
vmtBaseJSGenerator.buildingBlocksCode = (/**@type {Workspace}*/workspace) => {
    // get VMT blocks for the tests
    const blocks = workspace.getTopBlocks(false);
    let baseTestBlock = blocks.find(block => block.type === 'base_test');
    if (baseTestBlock === undefined) return '';

    const allBlocks = baseTestBlock.getDescendants();

    // TODO: probably use of 'reduce' is overkill - simple 'forEach' might a better solution
    // generate PageObjects' code
    // let buildingBlockCode = "";
    const bricksPOCode = allBlocks.reduce((vmtBricks, block) => {
        if (block.type === 'vmt_brick' && block.isEnabled()) {
            const vmtBrickId = block.data ? JSON.parse(block.data).id : block.type;
            if (!(vmtBrickId in vmtBricks)) {
                vmtBricks[vmtBrickId] = getPOCode(block);
                // buildingBlockCode += vmtBricks[vmtBrickId];//getPOCode(block);
            }
        }
        return vmtBricks;
    }, {});
    // return buildingBlockCode;
    return bricksPOCode;
};

//==============================================================================
// Override Blockly.JavaScript.finish method
//==============================================================================
vmtBaseJSGenerator.finish = function (code) {
    // // Add user variables, but only ones that are being used.
    // let variables = [];
    // if (this.definitions_['variables']) {
    //     variables = this.definitions_['variables']
    //         .replaceAll('var', '')
    //         .replaceAll(';', '')
    //         .replaceAll(' ', '')
    //         .split(',');
    // }
    // console.log('VAR', variables);
    // let varsCode = [
    //     '',
    //     '// construction\'s variables',
    //     'const varsObj = {',
    //     '  __vmtLastRetVal__: undefined,',
    //     ''
    // ].join('\n');
    // // Declare all of the variables.
    // variables.forEach((variableName) => {
    //     varsCode += `  ${variableName}: undefined,\n`;
    // });
    // varsCode += '};';

    // Delete variables definitions
    delete this.definitions_['variables'];

    // Call Blockly.Generator's finish.
    code = Object.getPrototypeOf(this).finish.call(this, code);
    this.isInitialized = false;

    this.nameDB_.reset();
    // return wdioImportsCode + varsCode + code;
    return code;
}

//==============================================================================
// Override workspaceToCode method
//==============================================================================
vmtBaseJSGenerator.workspaceToCode = function (workspace) {
    return Generator.prototype.workspaceToCode.call(this, workspace);
}

//========================================================================================
// Blocks generation codes
//========================================================================================

baseForBlock['value_text'] = (/*block*/) => {
    return ['value_text', Order.ATOMIC];
}
//========================================================================================
// code generator for the 'vmt_brick' block
baseForBlock['vmt_brick'] = (block, generator) => {
    const vmtBrickId = JSON.parse(block.data).id;
    const code = generator.statementToCode(block, 'CONTROLS');
    if (code === '') return '';

    const brickCode = [
        `// brick id: ${vmtBrickId}`,
        'async () => await vmtFw.statementWrap({',
        `  id: '${block.id}',`,
        '  cbs: [',
        `${code}`,
        '  ]',
        '}),',
        ''
    ].join('\n');
    return brickCode;
};

baseForBlock['vmt_group'] = (block, generator) => {
    let groupStatementCode = generator.statementToCode(block, 'GROUP_STATEMENT');
    if (groupStatementCode === '') return '';

    groupStatementCode = generator.prefixLines(groupStatementCode, generator.INDENT);

    const groupCode = [
        `// block id: '${block.id}'`,
        'async () => await vmtFw.statementWrap({',
        `  id: '${block.id}',`,
        '  cbs: [',
        `${groupStatementCode}`,
        '  ]',
        '}),',
        ''
    ].join('\n');
    return groupCode;
};

baseForBlock['base_test'] = function (block, generator) {
    const testContent = generator.statementToCode(block, 'TEST_CONTENT');
    // let testName = block.getFieldValue('TEST_NAME').trim().toLowerCase();
    // testName = testName.replace(/\W/g, '_').replace(/^(\d)/, '_$1');

    const constructionCode = [
        // '',
        '// a construction definition',
        // `const ${testName} = async () => {${testContent}}`,
        `const construction = async () => {${testContent}}`,
        '',
        // '// call the construction',
        // `${testName}().catch(async (ex) => {`,
        // '  const browser = await browserObject.browser;',
        // '  browser && browser.deleteSession();',
        // `  console.log('Failed', ex);`,
        // '});',
        'export default construction;',
        '',
        `// IPC 'remote control' for a runner`,
        `process.on('message', (message) => {`,
        '  // @ts-ignore',
        '  const { cmd } = message;',
        `  if (cmd === 'start') {`,
        '    construction()',
        '      .then(exitCode => process.exit(exitCode))',
        '      .catch(err => process.exit(err));',
        '  }',
        '  else {',
        '    process.exit(1);',
        '  }',
        '});',
        ''
    ].join('\n');

    return constructionCode;
};

baseForBlock['webdriverio_sync'] = function (block, generator) {
    let testContent = generator.statementToCode(block, 'TEST_CONTENT');
    testContent = generator.prefixLines(testContent, generator.INDENT);
    let configAndContentCode = [
        '',
        'let result = true;',
        'try {',
        '  vmtFw.setBrowserCtx(await remote({',
        `    runner: 'local',`,
        '    outputDir: __dirname,',
        `    automationProtocol: 'devtools',`,
        '    capabilities: {',
        `      browserName: 'chrome'`,
        '    }',
        '  }));',
        '',
        `  result = await vmtFw.statementWrap({ id: '${block.id}', cbs: [`,
        `${testContent}  ]});`,
        '  await vmtFw.getBrowserCtx().deleteSession();',
        '}',
        'catch (ex) {',
        '  await vmtFw.getBrowserCtx().deleteSession();',
        `  console.error('Error:', ex);`,
        '  // return false;',
        '  return 3;',
        '}',
        `console.log(result ? 'Passed' : 'Failed');`,
        '// return result ? true : false;',
        'return result ? 0 : 3;',
        ''
    ].join('\n');
    return configAndContentCode;
}

const ACTION_TYPE_CONSTRAINT = 'CONSTRAINT';
const ACTION_TYPE_ACTION = 'ACTION';
// const ACTION_TYPE_GETTER = 'GETTER';
// const ACTION_TYPE_VERIFY = 'VERIFY';

const wrapConstraintCode = (code) => `{"${ACTION_TYPE_CONSTRAINT}": {"Code": ${JSON.stringify(code)}}}`;
const wrapActionCode = (code) => `{"${ACTION_TYPE_ACTION}": {"Code": ${JSON.stringify(code)}}}`;

// const wrapGetterCode = (code) => wrapActionWrapper(`${ACTION_TYPE_GETTER}${ACTION_DELIMETER}${code}`);
// const wrapVerifyCode = (code) => wrapActionWrapper(`${ACTION_TYPE_VERIFY}${ACTION_DELIMETER}${code}`);
//==============================================================================
// Feilds/Controls
//==============================================================================
baseForBlock['vmt_control'] =
    baseForBlock['vmt_control_last'] = function (block, generator) {
        let actionsCode = generator.valueToCode(block, 'ACTION', Order.ATOMIC) || '';
        // retreive brick's infromation
        const parentBlock = block.getSurroundParent();
        const vmtBrickId = JSON.parse(parentBlock.data).id;
        const controlId = JSON.parse(block.data).id;
        // generate "base" code for the given control
        const controlScreenProperty = `${vmtBrickPrefix}${vmtBrickId}['${controlId}']`;
        let controlCode = ''
        if (actionsCode !== '') {
            controlCode += `// block id: '${block.id}'\n`;
            const re = /\}\s*\{/g;
            const delimeter = '>>|<<';
            const actionCodePiped = actionsCode.replaceAll(re, `}${delimeter}{`);
            const actions_ = actionCodePiped.split(delimeter);
            const actionsJson = actions_.map(act => JSON.parse(act));
            let selectorCode = '';
            let actCode = '';
            // iterate throught actions stack
            actionsJson.forEach(actionJson => {
                if (actionJson[ACTION_TYPE_CONSTRAINT]) {
                    let constraintCode = actionJson[ACTION_TYPE_CONSTRAINT]['Code'];
                    constraintCode = generator.prefixLines(constraintCode, generator.INDENT);
                    // selectorCode += `${constraintCode}, `;
                    selectorCode += `\n${constraintCode}\n`;
                }
                else if (actionJson[ACTION_TYPE_ACTION]) {
                    let actionCode = actionJson[ACTION_TYPE_ACTION]['Code'];
                    actionCode = generator.prefixLines(actionCode, generator.INDENT);
                    actCode += `{\n${actionCode}\n},\n`;
                }
            });
            actCode = generator.prefixLines(actCode, generator.INDENT + generator.INDENT);
            selectorCode = generator.prefixLines(selectorCode, generator.INDENT);
            controlCode = [
                `// control id ${controlId}`,
                'async () => await vmtFw.controlWrap({',
                `  id: '${block.id}',`,
                `  anchor: ${controlScreenProperty},`,
                `  selector: {${selectorCode}  },`,
                // '  },',
                '  actions: [',
                `${actCode}`,
                '  ]',
                '}),'
            ].join('\n');
            controlCode = generator.prefixLines(controlCode, generator.INDENT);
        }
        return controlCode;
    };

baseForBlock['vmt_control_orig'] =
    baseForBlock['vmt_control_last_orig'] = function (block, generator) {
        let actionsCode = generator.valueToCode(block, 'ACTION', Order.ATOMIC) || '';
        let code = '';
        if (actionsCode !== '') {
            code += `// block id: '${block.id}'\n`;
            code += `controlWrap(\n`;
            const re = /\}\s*\{/g;
            const actionCodePiped = actionsCode.replaceAll(re, '}|{');
            const actions_ = actionCodePiped.split('|');
            const actionsJson = actions_.map(act => JSON.parse(act));

            // retreive brick's infromation
            const parentBlock = block.getParent();
            const vmtBrickId = JSON.parse(parentBlock.data).id;
            const controlId = JSON.parse(block.data).id;

            // generate "base" code for the given control
            const controlScreenProperty = `${vmtBrickPrefix}${vmtBrickId}['${controlId}']`;
            let controlIdCode = `vmtProp$({ browser: browserObject.browser, selector: ${controlScreenProperty}`;

            // iterate throught actions stack
            actionsJson.forEach(actionJson => {
                if (actionJson[ACTION_TYPE_CONSTRAINT]) {
                    const constraintCode = actionJson[ACTION_TYPE_CONSTRAINT]['Code'];
                    controlIdCode += `, ${constraintCode}`;
                }
                else if (actionJson[ACTION_TYPE_ACTION]) {
                    const actionCode = actionJson[ACTION_TYPE_ACTION]['Code'];
                    code += `  ${controlIdCode}, ${actionCode} }),\n`;
                }
            })
            code += '),'
        }
        return code;
    };
//==============================================================================
// Actions
//==============================================================================
baseForBlock['browser_commands'] = function (block, generator) {
    const command = block.getFieldValue('COMMAND');
    const value = generator.valueToCode(block, 'VALUE', Order.ATOMIC);
    const commandMap = {
        'BROWSER_URL': 'url',
        'BROWSER_KEYS': 'keys',
        'BROWSER_NEW_WINDOW': 'newWindow',
        'BROWSER_SWITCH_WINDOW': 'switchWindow',
        'BROWSER_PAUSE': 'pause',
        'BROWSER_DEBUG': 'debug',
    };
    const commandCode = [
        `// block id: '${block.id}'`,
        'async () => await vmtFw.commandWrap({',
        `  id: '${block.id}',`,
        `  command: '${commandMap[command]}',`,
        `  args: [${value}]`,
        '}),',
        ''
    ].join('\n');

    return commandCode;
};

baseForBlock['action_constrained_stack'] = function (block, generator) {
    const const_type = block.getFieldValue('CONST_TYPE');
    const constraint = generator.valueToCode(block, 'CONSTRAINT', Order.ATOMIC);
    let code = `id: '${block.id}',\n`;
    // set constraint
    switch (const_type) {
        case 'BY_INDEX':
            code += `index: ${constraint}`;
            break;
        case 'BY_CONTENT':
            code += `regEx: ${constraint}`;
            break;
        default:
            break;
    }

    return [wrapConstraintCode(code), Order.ATOMIC];
}

const actionCode = (block) => {
    const action = block.getFieldValue('ACTION');
    let code = `id: '${block.id}',\n`;
    code += 'method: ';
    const actionMap = {
        'CLICK': "'click'",
        'DBL_CLICK': "'doubleClick'",
        'SELECT': "'select'",
        'HOVER': "'moveTo'",
    }
    if (actionMap[action]) {
        code += actionMap[action];
    }
    return code;
};

baseForBlock['action_type'] = function (block) {
    const code = actionCode(block);
    return [wrapActionCode(code), Order.ATOMIC];
};

baseForBlock['action_stack'] = function (block) {
    const code = actionCode(block);
    return wrapActionCode(code);
};

const actionTextCode = (block, generator) => {
    const action = block.getFieldValue('ACTION');
    const textValue = generator.valueToCode(block, 'TEXT', Order.ATOMIC);
    let code = `id: '${block.id}',\n`;
    code += 'method: ';
    switch (action) {
        case 'SET_VALUE':
            code += `'setValue', args: [${textValue}]`;
            break;
        case 'ADD_VALUE':
            code += `'addValue', args: [${textValue}]`;
            break;
        case 'CLEAR_VALUE':
            code += `'clearValue'`;
            break;
        default:
            break;
    }
    return code;
};

baseForBlock['action_text'] = function (block, generator) {
    const code = actionTextCode(block, generator);
    return [wrapActionCode(code), Order.ATOMIC];
};

baseForBlock['action_text_stack'] = function (block, generator) {
    const code = actionTextCode(block, generator);
    return wrapActionCode(code);
};

const attrGetterCode = (block, generator) => {
    const getterType = block.getFieldValue('GETTER_TYPE');
    const variableName = generator.nameDB_.getName(block.getFieldValue('VAR'), VARIABLE_CATEGORY_NAME);
    // TODO: Assemble JavaScript into code variable.
    const methods = {
        'TEXT': 'getText',
        'VALUE': 'getValue',
        'ATTRIBUTE': 'getAttribute'
    };
    let code = `id: '${block.id}',\n`;
    code += `method: '${methods[getterType]}', varRef: '${variableName}'`;
    if (getterType === 'ATTRIBUTE') {
        const attrName = block.getFieldValue('ATTRIBUTE_NAME');
        code += `, args: ['${attrName}']`
    }
    return code;
};

baseForBlock['attr_getter'] = function (block, generator) {
    const code = attrGetterCode(block, generator);
    return [wrapActionCode(code), Order.ATOMIC];
};

baseForBlock['attr_getter_stack'] = function (block, generator) {
    const code = attrGetterCode(block, generator);
    return wrapActionCode(code);
};

const expectActionCode = (block, generator) => {
    const methodMap = {
        'SELECTED': 'isSelected',
        'DISPLAYED': 'isDisplayed',
        'IN_VIEWPORT': 'isDisplayedInViewport',
        'ENABLED': 'isEnabled',
        'EXISTING': 'isExisting',
        'FOCUSED': 'isFocused',
        'TEXT': 'getText',
        'VALUE': 'getValue',
        'ATTRIBUTE': 'getAttribute',
    };
    const expectProperty = block.getFieldValue('EXPECT_PROPERTY');
    const compOperator = block.getFieldValue('OP');
    const expectedValue = generator.valueToCode(block, 'EXPECTED_VALUE', Order.ATOMIC);

    let code = [
        `id: '${block.id}',`,
        `method: '${methodMap[expectProperty]}',`,
        ''
    ].join('\n');

    if (expectProperty === 'ATTRIBUTE') {
        const attrName = block.getFieldValue('ATTRIBUTE_NAME');
        code += `args: ['${attrName}'],\n`;
    }
    code += [
        'expectedResult: {',
        `  operator: '${compOperator}',`,
        `  value: ${expectedValue}`,
        '}'
    ].join('\n');

    return code;
}

baseForBlock['expect_action'] = function (block, generator) {
    const code = expectActionCode(block, generator);
    return [wrapActionCode(code), Order.ATOMIC];
};

baseForBlock['expect_action_stack'] = function (block, generator) {
    const code = expectActionCode(block, generator);
    return wrapActionCode(code);
};

baseForBlock['evaluate_brick'] = function (block, generator) {
    const value = generator.valueToCode(block, 'VALUE', Order.ATOMIC);
    if (value === '') return '';
    const compOperator = block.getFieldValue('OP');
    const expectedValue = generator.valueToCode(block, 'EXPECTED_VALUE', Order.ATOMIC);

    const evaluateCode = [
        `// block id: '${block.id}'`,
        '() => compareWrap({',
        `  id: '${block.id}',`,
        `  value: ${value},`,
        '  expectedResult: {',
        `    operator: '${compOperator}',`,
        `    value: ${expectedValue}`,
        '  }',
        '}),',
        ''
    ].join('\n');
    return evaluateCode;
};

const isValidatorCode = (block, generator) => {
    const methodMap = {
        'IS_DISPLAYED': `'isDisplayed'`,
        'IS_IN_VIEWPORT': `'isDisplayedInViewport'`,
        'IS_ENABLED': `'isEnabled'`,
        'IS_EXISTING': `'isExisting'`,
        'IS_FOCUSED': `'isFocused'`,
        'IS_SELECTED': `'isSelected'`,
    };

    const isType = block.getFieldValue('IS_TYPE');
    const expectedValue = generator.valueToCode(block, 'EXPECTED_VALUE', Order.ATOMIC);

    let methodCode = 'method: ';
    if (methodMap[isType]) {
        methodCode += methodMap[isType];
    }
    let code = `id: '${block.id}',\n`;
    code += `${methodCode}, expectedResult: ${expectedValue}`;
    return code;
};

baseForBlock['is_validator'] = function (block) {
    const code = isValidatorCode(block);
    return [wrapActionCode(code), Order.ATOMIC];
};

baseForBlock['is_validator_stack'] = function (block) {
    const code = isValidatorCode(block);
    return wrapActionCode(code);
};

baseForBlock['text_concat'] = function (block, generator) {
    const textA = generator.valueToCode(block, 'TEXT_A', Order.ATOMIC);
    const textB = generator.valueToCode(block, 'TEXT_B', Order.ATOMIC);
    const code = `(${textA} + ${textB})`;
    return [code, Order.FUNCTION_CALL];
};

// VMT variable getter
baseForBlock['vmt_variables_get'] = function (block, generator) {
    var variableName = generator.nameDB_.getName(block.getFieldValue('VAR'), VARIABLE_CATEGORY_NAME);
    const code = `vmtFw.varsObj['${variableName}']`;
    return [code, Order.ATOMIC];
}

// VMT variable setter
baseForBlock['vmt_variables_set'] = function (block, generator) {
    // Variable setter
    var argument0 = generator.valueToCode(block, 'VALUE', Order.ASSIGNMENT) || '0';
    var variableName = generator.nameDB_.getName(block.getFieldValue('VAR'), VARIABLE_CATEGORY_NAME);
    const code = [
        '() => vmtFw.setVariable({',
        `  id: '${block.id}',`,
        `  variableName: '${variableName}',`,
        `  newValue: ${argument0}`,
        '}),',
        ''
    ].join('\n');
    return code;
};

baseForBlock['action_value_to_statement'] = function (block, generator) {
    const statementCode = generator.statementToCode(block, 'STATEMENT') || ' return false; ';
    const code = statementCode.split(';\n').join(' &&\n').replace(/&&\n$/, '\n');
    const header = 'vmtCb2Bool(() => {';
    const footter = '})';
    return [`${header}${code}${footter}`, Order.ATOMIC];
}

const returnStatementWrap = (code, generator) => {
    if (code === '') return '';
    const wrappedCode = [
        'return await vmtFw.statementWrap({ cbs: [',
        `${code}]})`,
        ''
    ].join('\n');
    return generator.prefixLines(wrappedCode, generator.INDENT);
};

const statementWrap = (code, generator) => {
    if (code === '') return '';
    const wrappedCode = [
        'await vmtFw.statementWrap({ cbs: [',
        `${code}]})`
    ].join('\n');
    return wrappedCode;
};

const asyncWrap = (code, generator) => {
    const indentedCode = generator.prefixLines(code, generator.INDENT);
    const wrappedCode = [
        'async () => {',
        `${indentedCode}`,
        '},',
        ''
    ].join('\n');
    return wrappedCode
};

baseForBlock['if_statement'] = function (block, generator) {
    // If/elseif/else condition.
    let n = 0;
    let code = '', branchCode, conditionStatementCode;
    do {
        conditionStatementCode = generator.statementToCode(block, 'IF' + n) || 'false';
        branchCode = generator.statementToCode(block, 'DO' + n);
        conditionStatementCode = generator.prefixLines(conditionStatementCode, generator.INDENT);
        const conditionWrapCode = [
            'await conditionWrap({',
            `  id: '${block.id}',`,
            '  cbs: [',
            `${conditionStatementCode}  ]`,
            '})'
        ].join('\n');

        code += (n > 0 ? ' else ' : '') +
            'if (' + conditionWrapCode + ') {\n' + returnStatementWrap(branchCode, generator) + '}';
        ++n;
    } while (block.getInput('IF' + n));

    if (block.getInput('ELSE')) {
        branchCode = generator.statementToCode(block, 'ELSE');
        code += ' else {\n' + returnStatementWrap(branchCode, generator) + '}';
    }

    return asyncWrap(code, generator);
}

baseForBlock['controls_if'] = function (block, generator) {
    // If/elseif/else condition.
    var n = 0;
    var code = '', branchCode, conditionCode;
    if (generator.STATEMENT_PREFIX) {
        // Automatic prefix insertion is switched off for this block.  Add manually.
        code += generator.injectId(generator.STATEMENT_PREFIX, block);
    }
    do {
        conditionCode = generator.valueToCode(block, 'IF' + n, Order.NONE) || 'false';
        branchCode = generator.statementToCode(block, 'DO' + n);
        if (generator.STATEMENT_SUFFIX) {
            branchCode = generator.prefixLines(
                generator.injectId(generator.STATEMENT_SUFFIX,
                    block), generator.INDENT) + branchCode;
        }
        code += (n > 0 ? ' else ' : '') +
            'if (' + conditionCode + ') {\n' + returnStatementWrap(branchCode, generator) + '}';
        ++n;
    } while (block.getInput('IF' + n));

    if (block.getInput('ELSE') || generator.STATEMENT_SUFFIX) {
        branchCode = generator.statementToCode(block, 'ELSE');
        if (generator.STATEMENT_SUFFIX) {
            branchCode = generator.prefixLines(
                generator.injectId(generator.STATEMENT_SUFFIX,
                    block), generator.INDENT) + branchCode;
        }
        code += ' else {\n' + returnStatementWrap(branchCode, generator) + '}';
    }

    return asyncWrap(code, generator);
};

baseForBlock['controls_ifelse'] = baseForBlock['controls_if'];

baseForBlock['controls_repeat_ext'] = function (block, generator) {
    // Repeat n times.
    let repeats = '';
    if (block.getField('TIMES')) {
        // Internal number.
        repeats = String(Number(block.getFieldValue('TIMES')));
    } else {
        // External number.
        repeats = generator.valueToCode(block, 'TIMES',
            Order.ASSIGNMENT) || '0';
    }
    var branch = generator.statementToCode(block, 'DO');
    if (branch === '') return '';

    branch = generator.addLoopTrap(branch, block);
    var code = '// repeat\n';
    var loopVar = generator.nameDB_.getDistinctName(
        'count', VARIABLE_CATEGORY_NAME);
    var endVar = repeats;
    if (!repeats.match(/^\w+$/) && !utils.string.isNumber(repeats)) {
        endVar = generator.nameDB_.getDistinctName(
            'repeat_end', VARIABLE_CATEGORY_NAME);
        code += 'let ' + endVar + ' = ' + repeats + ';\n';
    }
    code += 'let result = true;\n'
    branch = statementWrap(branch, generator);
    branch = `result = ${branch} && result;\n`
    branch = generator.prefixLines(branch, generator.INDENT);
    code += 'for (let ' + loopVar + ' = 0; ' +
        loopVar + ' < ' + endVar + '; ' +
        loopVar + '++) {\n' +
        branch + '}\n' +
        'return result;'
    return asyncWrap(code, generator);
};