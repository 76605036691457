// React
import { useState, useEffect, useRef, useCallback } from 'react';
import { /*autorun,*/ reaction } from 'mobx';

// Blockly
import Blockly from 'blockly/core';
// import {javascriptGenerator as JavaScript} from 'blockly/javascript';

// VMT Blockly
// import 'blockly_vmt/generator/generator';
import { ToCode } from 'blockly_vmt/vavanya-generator';


// VMT
import { blocklyEventStore, selectedBrickIDsStore, useStore } from 'stores';
import { useAppContext } from "app-context";

/**
 * Import JSDocs types 
 * @import {ConstructionCodeDetails} from '../../blockly_vmt/vavanya-generator/src/index.js'
 */
// /**
//  * @typedef {Object} ConstructionCodeDetails
//  * @property {string} importsCode
//  * @property {string} constructionCode
//  * @property {Object} frameworkCode
//  * @property {Object} buildingBlocksCode
//  */

const useBlocklyEvents = () => {

    const { store } = useAppContext();

    const basementWorkspaceRef = useRef(null);
    const [constructionDetails, setConstructionDetails] = useState(null);
    const [wsXML, setWsXML] = useState(null);
    // const [logContentToScroll, setLogContentToScroll] = useState('');
    const [blocklyEvent] = useStore(blocklyEventStore, null);
    const [flyoutBrickIds] = useStore(selectedBrickIDsStore);

    useEffect(() => {
        // console.log(blocklyEvent);
        if (blocklyEvent === null ||
            blocklyEvent.blocklyEvent === null ||
            blocklyEvent.blocklyWorkspace === null) return;
        const workspace = blocklyEvent.blocklyWorkspace;
        // console.log('>>> Blockly Event', blocklyEvent.blocklyEvent.type, blocklyEvent.blocklyEvent.isStart);
        // console.log('VApp event', blocklyEvent.blocklyEvent);
        const reactiveBlocklyEvents = [
            Blockly.Events.BLOCK_DRAG,
            Blockly.Events.MOVE, // NOTE: this event might "bombard" triggering the code to be updated way too many times
            Blockly.Events.FINISHED_LOADING,
            Blockly.Events.CREATE,
            Blockly.Events.CHANGE,
            Blockly.Events.DELETE,
            Blockly.Events.VAR_RENAME
        ];
        if (/*(blocklyEvent.blocklyEvent.type === Blockly.Events.BLOCK_DRAG && blocklyEvent.blocklyEvent.isStart === false) ||*/
            reactiveBlocklyEvents.includes(blocklyEvent.blocklyEvent.type)) {
            // setJSCode(JavaScript.workspaceToCode(workspace));
            // setJSCode(ToCode(workspace));
            // XML construction description
            // const xmlDom = Blockly.Xml.workspaceToDom(workspace);
            // setWsXML(Blockly.Xml.domToPrettyText(xmlDom));
            // JSON construction description
            const wsJson = Blockly.serialization.workspaces.save(workspace);

            const /**@type {ConstructionCodeDetails}*/ constrCodeDetails = ToCode(JSON.stringify(wsJson));
            setConstructionDetails(constrCodeDetails);
            setWsXML(JSON.stringify(wsJson, null, 2));
            return;
        }
    }, [blocklyEvent]);

    useEffect(() => {
        // console.log(blocklyEvent);
        if (blocklyEvent === null ||
            blocklyEvent.blocklyEvent === null ||
            blocklyEvent.blocklyWorkspace === null) return;
        const workspace = blocklyEvent.blocklyWorkspace;
        // setBasementWorkspace(workspace);
        basementWorkspaceRef.current = workspace;
        if (blocklyEvent.blocklyEvent.type === Blockly.Events.VAR_CREATE) {
            console.log('VApp: Blockly.Events.VAR_CREATE', blocklyEvent.blocklyEvent);
            setTimeout(() => {
                const event = blocklyEvent.blocklyEvent;
                if (event.varName === '>attr') {
                    const varNameRe = /^attr(\d+)/i;
                    const varMap = workspace.getVariableMap();
                    const allVarNamres = varMap.getAllVariableNames();
                    console.log('varMap', varMap);
                    console.log('allVarNamres', allVarNamres);
                    const similarNames = allVarNamres.filter(name => name.match(varNameRe) !== null);
                    console.log('similarNames', similarNames);
                    try {
                        if (similarNames.length === 0) {
                            workspace.renameVariableById(event.varId, `attr${similarNames.length + 1}`);
                        }
                        else {
                            similarNames.sort();
                            let lastIndex = similarNames[similarNames.length - 1].match(varNameRe)[1];
                            lastIndex = parseInt(lastIndex) + 1;
                            workspace.renameVariableById(event.varId, `attr${lastIndex}`);
                        }
                    }
                    catch (e) {
                        console.error('Blockly variable rename exception', e);
                    }
                }
            }, 4000);
        }
    }, [blocklyEvent]);

    const updateBricksFlyout = useCallback((basementWorkspace) => {
        console.log('updateBricksFlyout');
        console.log('flyoutBrickIds', flyoutBrickIds);

        const filteredFlyoutBrickIds = flyoutBrickIds.filter(brickId => store.brick.byId(brickId) !== undefined);

        Blockly.Flyout.prototype.autoClose = true;
        const toolbox = basementWorkspace.getToolbox();
        toolbox.clearSelection();

        const blocks = filteredFlyoutBrickIds.map(id => {
            let block = Blockly.utils.xml.createElement('block');
            // block.setAttribute('type', id);
            block.setAttribute('type', 'vmt_brick');
            block.setAttribute('is', 'blockly');
            const brick = store.brick.byId(id);

            let field = Blockly.utils.xml.createElement('field');
            field.setAttribute('name', 'brick_caption');
            field.appendChild(Blockly.utils.xml.createTextNode(brick.name));
            block.appendChild(field);

            let brickData = Blockly.utils.xml.createElement('data');
            brickData.appendChild(Blockly.utils.xml.createTextNode(JSON.stringify({ id: id })));
            block.appendChild(brickData);
            // add controls
            if (brick.controls.length > 0) {
                let fields = brick.controls;
                // find the longest text
                const maxFieldLength = fields.reduce((maxLength, { name }) => { return Math.max(maxLength, name.length) }, 0);
                fields = fields.map(field => { return { ...field, name: field.name.padEnd(maxFieldLength) }; });
                const lastField = fields.slice(-1)[0];
                let controlsBlock = null;
                let currentBlock = null;
                fields.forEach(({ name, id, anchor }) => {
                    let block = Blockly.utils.xml.createElement('block');
                    // add metadata to control block
                    let controlData = Blockly.utils.xml.createElement('data');
                    controlData.appendChild(Blockly.utils.xml.createTextNode(JSON.stringify({ id: id, ID: anchor })));
                    block.appendChild(controlData);

                    if (lastField.name !== name) {
                        block.setAttribute('type', 'vmt_control');
                    }
                    else {
                        block.setAttribute('type', 'vmt_control_last');
                    }
                    block.setAttribute('movable', 'false');
                    block.setAttribute('deletable', 'false');
                    let field = Blockly.utils.xml.createElement('field');
                    field.setAttribute('name', 'CONTROL_NAME');
                    field.appendChild(Blockly.utils.xml.createTextNode(name));
                    block.appendChild(field);
                    if (controlsBlock === null) {
                        currentBlock = controlsBlock = block;
                    }
                    else {
                        let nextContainer = Blockly.utils.xml.createElement('next');
                        nextContainer.appendChild(block);
                        currentBlock.appendChild(nextContainer);
                        currentBlock = block;
                    }
                });
                let controlsContainer = Blockly.utils.xml.createElement('value');
                controlsContainer.setAttribute('name', 'CONTROLS');
                controlsContainer.appendChild(controlsBlock);
                block.appendChild(controlsContainer);
            }
            return block;
        });

        // add blocks to the first item in the toolbox
        const category = toolbox.getToolboxItems()[0];
        category.setSelected(true);
        category.updateFlyoutContents(blocks);
        toolbox.setSelectedItem(category);
    }, [flyoutBrickIds, store.brick]);

    useEffect(() => {
        const disposer = reaction(
            () => [...store.brick.bricksById],
            () => {
                console.log('>>> bricksData CHANGED <<<');
                if (basementWorkspaceRef.current) {
                    // update flyout
                    updateBricksFlyout(basementWorkspaceRef.current);
                    // hide flyout
                    const toolbox = basementWorkspaceRef.current.getToolbox();
                    toolbox.clearSelection();
                }
            });

        return () => {
            disposer();
        }
    }, [store, updateBricksFlyout]);

    useEffect(() => {
        console.log('showFlyout');
        // if (!basementWorkspace) return;
        if (!basementWorkspaceRef.current) return;
        console.log('showFlyout', flyoutBrickIds);
        updateBricksFlyout(basementWorkspaceRef.current);
    }, [flyoutBrickIds, updateBricksFlyout]);

    return {
        /**@type {ConstructionCodeDetails}*/
        constructionDetails,
        setConstructionDetails,
        wsXML,
        setWsXML,
        basementWS: basementWorkspaceRef.current,
    };
}

export default useBlocklyEvents;